import React, { useState, useEffect, useRef } from "react";
import logo from "../logos/crazyClaims_Rough_Logo_1.png";
import notificationsIcon from "../icons/crazyClaims_Notifications_Icon.png";
import accountIcon from "../icons/crazyClaims_Account_Icon.png";
import notesIcon from "../icons/crazyClaim_Notes_Icon.png";
import Chart from "chart.js/auto";
import "../stylings/FilerDashboard.css";
import profileImage from "../imgs/filerProfileImage_01.webp";
import { signOut } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "./Header"; // Import Sidebar from Header
import apiBaseUrls from "../config";

const FilerDashboard = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarMetrics, setSidebarMetrics] = useState({
    claimsFiled: 0,
    claimsProcessed: 0,
    outstandingClaims: 0,
    user_info: { first_name: "", last_name: "", email: "" },
  });
  const [claims, setClaims] = useState([
    {
      id: "0012350",
      type: "Accident",
      submitter: "John Doe",
      dateFiled: "08/28/2024",
      details:
        "Minor accident, rear-ended at a traffic light. Damages include rear bumper and trunk.",
      status: "Pending Review",
      files: ["Document.pdf", "Image.png"],
    },
    {
      id: "0012351",
      type: "Theft",
      submitter: "Jane Smith",
      dateFiled: "08/29/2024",
      details:
        "Theft of cargo during transit. Estimated loss of $10,000 in electronics.",
      status: "Under Investigation",
      files: ["Report.pdf", "SecurityFootage.mp4"],
    },
    {
      id: "0012352",
      type: "Damage",
      submitter: "Alex Johnson",
      dateFiled: "08/30/2024",
      details:
        "Significant damage to goods due to improper handling. Damaged items include fragile glassware.",
      status: "Resolved",
      files: ["DamageReport.pdf", "Photos.zip"],
    },
  ]);

  const [metrics, setMetrics] = useState({
    outstandingClaims: 0,
    inProgressClaims: 0,
    resolvedClaims: 0,
    totalClaims: 0,
  });

  const [outstandingClaims, setOutstandingClaims] = useState([]);
  const [resolvedClaims, setResolvedClaims] = useState([]);
  const [currentFiler, setCurrentFiler] = useState("");
  const [claimsFiled, setClaimsFiled] = useState(15);
  const [claimsProcessed, setClaimsProcessed] = useState(8);

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [noteInput, setNoteInput] = useState("");
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [savedNotes, setSavedNotes] = useState([
    "1: Follow up with the client by end of the week.",
    "2: Awaiting additional documentation from insurance.",
    "3: Call the bondsman to confirm details.",
  ]);
  const [chatHistory, setChatHistory] = useState([
    {
      sender: "John Doe",
      message:
        "I’ve submitted all the required documents. Please let me know if you need anything else.",
    },
    {
      sender: "You",
      message:
        "Thank you, John. We will review them and get back to you shortly.",
    },
    {
      sender: "John Doe",
      message: "Alright, thanks for the update!",
    },
  ]);
  const [messageInput, setMessageInput] = useState("");

  const claimsChartRef = useRef(null);
  const filersClaimsChartRef = useRef(null);
  const navigate = useNavigate();

  const [showMore, setShowMore] = useState(false);
  const claimsPerRow = 3; // We want 3 claims (1 row)

  const [supportRequests, setSupportRequests] = useState([]);

  useEffect(() => {
    console.log(`Current Environment: ${process.env.NODE_ENV}`);
    console.log(
      "metric endpoint: ",
      `${apiBaseUrls.filer_dashboard}/filer-dashboard-claim-metrics`
    );
    const fetchMetrics = async () => {
      const jwtToken = sessionStorage.getItem("jwtToken");
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-dashboard-claim-metrics`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched metrics data:", data);
        setMetrics({
          outstandingClaims: data.outstanding_claims,
          inProgressClaims: data.in_progress_claims,
          resolvedClaims: data.resolved_claims,
          totalClaims: data.total_claims,
        });
      } else {
        console.error("Failed to fetch metrics:", response.statusText);
      }
    };

    fetchMetrics();
  }, []);

  const fetchMetricsAndOutstandingClaims = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const metricsResponse = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-dashboard-claim-metrics`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (metricsResponse.ok) {
        const metricsData = await metricsResponse.json();
        setMetrics({
          outstandingClaims: metricsData.outstanding_claims,
          inProgressClaims: metricsData.in_progress_claims,
          resolvedClaims: metricsData.resolved_claims,
          totalClaims: metricsData.total_claims,
        });
      }

      const outstandingResponse = await fetch(
        `${apiBaseUrls.filer_dashboard}/get-outstanding-claims`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (outstandingResponse.ok) {
        const outstandingData = await outstandingResponse.json();
        console.log("Outstanding Claims Data: ", outstandingData);
        setOutstandingClaims(outstandingData.claims);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPickedUpClaims = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    const userId = sessionStorage.getItem("user_id"); // Assuming user_id is stored in session storage

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-picked-up-claims`, // Replace with your actual API endpoint
        {
          method: "POST", // Or GET depending on your API setup
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId }), // Pass the user_id in the request body
        }
      );

      if (response.ok) {
        const pickedUpClaimsData = await response.json();
        setClaims(pickedUpClaimsData.claims); // Update the claims state with the picked-up claims
        console.log("Picked up claims for filer: ", pickedUpClaimsData.claims);
      } else {
        console.error("Failed to fetch picked-up claims:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching picked-up claims:", error);
    }
  };

  useEffect(() => {
    const userFirstName = sessionStorage.getItem("user_first_name");
    console.log("User First Name: ", userFirstName); // This will log the value
    if (userFirstName) {
      setCurrentFiler(userFirstName);
    }
  }, []);

  useEffect(() => {
    fetchMetricsAndOutstandingClaims();
    fetchPickedUpClaims();
    fetchResolvedClaimsCount(); // Fetch resolved claims count on initial load

    if (claimsChartRef.current && filersClaimsChartRef.current) {
      const claimsChartCtx = claimsChartRef.current.getContext("2d");
      const filersChartCtx = filersClaimsChartRef.current.getContext("2d");

      const claimsChartInstance = new Chart(claimsChartCtx, {
        type: "bar",
        data: {
          labels: ["January", "February", "March", "April", "May", "June"],
          datasets: [
            {
              label: "Claims Filed",
              data: [10, 15, 20, 25, 30, 40],
              backgroundColor: "#D4AF37",
            },
            {
              label: "Income Earned ($)",
              data: [2375, 3562.5, 4750, 5937.5, 7125, 9500],
              backgroundColor: "#3A506B",
            },
          ],
        },
      });

      const filersChartInstance = new Chart(filersChartCtx, {
        type: "bar",
        data: {
          labels: ["January", "February", "March", "April", "May", "June"],
          datasets: [
            {
              label: "Rory",
              data: [2, 4, 6, 8, 10, 12],
              backgroundColor: "#FF6384",
            },
            {
              label: "Hasan",
              data: [4, 8, 12, 16, 20, 24],
              backgroundColor: "#36A2EB",
            },
            {
              label: "Josh",
              data: [6, 12, 18, 24, 30, 36],
              backgroundColor: "#FFCE56",
            },
          ],
        },
      });

      return () => {
        claimsChartInstance.destroy();
        filersChartInstance.destroy();
      };
    }
  }, []); // Added return cleanup function and proper closure

  const pickUpClaim = async (claimId) => {
    const userId = sessionStorage.getItem("user_id"); // Assuming user_id is stored in session storage

    const payload = {
      claim_id: claimId,
      user_id: userId,
    };

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/pickup-outstanding-claim`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`, // Include JWT if necessary
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        console.log("Claim picked up successfully");
        // Optionally update the frontend state to reflect the change
        await fetchMetricsAndOutstandingClaims();
        await fetchPickedUpClaims();
      } else {
        console.error("Failed to pick up claim:", response.statusText);
      }
    } catch (error) {
      console.error("Error picking up claim:", error);
    }
  };

  // Define toggleSidebar function
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      alert("Logging out...");
      setSidebarOpen(false); // Close sidebar on logout
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const saveNote = async () => {
    if (!noteInput.trim() || !selectedClaimId) return;

    const jwtToken = sessionStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/claim-notes-post`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            claim_id: selectedClaimId,
            note_text: noteInput.trim(),
          }),
        }
      );

      if (response.ok) {
        alert("Note saved successfully!");
        setNoteInput("");
        closeNotesModal();
      } else {
        const errorData = await response.json();
        console.error("Failed to save note:", errorData.message);
        alert("Failed to save note. Please try again.");
      }
    } catch (error) {
      console.error("Error saving note:", error);
      alert("An error occurred while saving the note.");
    }
  };

  const fetchNotesForClaim = async (claimId) => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/claim-notes-get?claim_id=${claimId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched notes data:", data.notes);
        setSavedNotes(data.notes);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch notes:", errorData.message);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const openNotesModal = async (claimId) => {
    console.log("Opening notes modal for claim ID:", claimId);
    setSelectedClaimId(claimId);
    await fetchNotesForClaim(claimId); // Fetch notes before showing the modal
    setIsNotesModalOpen(true);
  };

  const closeNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const openMessageModal = async (claimId) => {
    console.log("Opening message modal for claim ID:", claimId);
    setSelectedClaimId(claimId);
    await fetchRemarksForClaim(claimId); // Fetch remarks before showing the modal
    setIsMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setIsMessageModalOpen(false);
  };

  const sendMessage = async () => {
    if (!messageInput.trim() || !selectedClaimId) return;

    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const response = await fetch(`${apiBaseUrls.claims}/claim-remarks-post`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          claim_id: selectedClaimId,
          remark_text: messageInput.trim(),
          user_id: sessionStorage.getItem("user_id"),
          severity: "Normal", // Change or make dynamic based on admin input
        }),
      });

      if (response.ok) {
        alert("Remark saved successfully!");
        setMessageInput("");
        closeMessageModal();
      } else {
        const errorData = await response.json();
        console.error("Failed to save remark:", errorData.message);
        alert("Failed to save remark. Please try again.");
      }
    } catch (error) {
      console.error("Error saving remark:", error);
      alert("An error occurred while saving the remark.");
    }
  };

  const fetchRemarksForClaim = async (claimId) => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const response = await fetch(
        `${apiBaseUrls.claims}/claim-remarks-get?claim_id=${claimId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched remarks data:", data.remarks);
        setChatHistory(data.remarks); // Assuming chatHistory holds the remarks
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch remarks:", errorData.message);
      }
    } catch (error) {
      console.error("Error fetching remarks:", error);
    }
  };

  const changeClaimStatus = async (claimId, newStatus) => {
    try {
      const jwtToken = sessionStorage.getItem("jwtToken");

      if (!jwtToken) {
        console.error("JWT token missing. Please log in.");
        return;
      }

      // Ensure you are passing claim_id here
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-claim-change-status`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            claim_id: claimId, // Ensure claim_id is passed
            status: newStatus,
          }),
        }
      );

      if (response.ok) {
        // Update the claim status in the UI
        setClaims((prevClaims) =>
          prevClaims.map((claim) =>
            claim.claim_id === claimId ? { ...claim, status: newStatus } : claim
          )
        );
        console.log(`Claim ${claimId} status updated to ${newStatus}`);

        // Close the active dropdown after successful status change
        setActiveDropdown(null);
      } else {
        const errorData = await response.json();
        console.error("Failed to change claim status:", errorData.message);
      }
    } catch (error) {
      console.error("Error changing claim status:", error);
    }
  };

  const submitResolution = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    // Construct payload, omitting email if it is empty or null
    const payload = {
      claim_id: selectedClaimId,
      originalClaimNumber: bondsmanInfo.originalClaimNumber,
      companyName: bondsmanInfo.companyName,
      phoneNumber: bondsmanInfo.phoneNumber,
    };

    // Add email to payload only if it is provided
    if (bondsmanInfo.email) {
      payload.email = bondsmanInfo.email;
    }

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-claim-mark-resolved`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        alert("Claim marked as resolved and email sent to customer!");

        // Remove the resolved claim from the UI
        setClaims((prevClaims) =>
          prevClaims.filter((claim) => claim.claim_id !== selectedClaimId)
        );

        // Fetch updated resolved claims count
        await fetchResolvedClaimsCount();

        closeResolveModal(); // Close modal
      } else {
        const errorData = await response.json();
        console.error("Failed to mark claim as resolved:", errorData.message);
        alert("Failed to mark claim as resolved. Please try again.");
      }
    } catch (error) {
      console.error("Error marking claim as resolved:", error);
      alert("An error occurred while marking the claim as resolved.");
    }
  };

  const markClaimCompleted = async (claimId) => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-claim-mark-resolved`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ claim_id: claimId }),
        }
      );

      if (response.ok) {
        alert("Claim marked as resolved successfully!");

        // Remove the resolved claim from the UI
        setClaims((prevClaims) =>
          prevClaims.filter((claim) => claim.claim_id !== claimId)
        );

        // Fetch updated resolved claims count
        await fetchResolvedClaimsCount();
      } else {
        const errorData = await response.json();
        console.error("Failed to mark claim as resolved:", errorData.message);
        alert("Failed to mark claim as resolved. Please try again.");
      }
    } catch (error) {
      console.error("Error marking claim as resolved:", error);
      alert("An error occurred while marking the claim as resolved.");
    }
  };

  const fetchResolvedClaimsCount = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-claim-resolved-count-get`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          resolvedClaims: data.resolved_claims,
        }));
      } else {
        console.error(
          "Failed to fetch resolved claims count:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching resolved claims count:", error);
    }
  };

  const fetchResolvedClaims = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-claim-resolved-get`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Resolved Claims Data: ", data.resolved_claims);
        setResolvedClaims(data.resolved_claims);
      } else {
        console.error("Failed to fetch resolved claims:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching resolved claims:", error);
    }
  };

  // Call fetchResolvedClaims on component mount
  useEffect(() => {
    fetchResolvedClaims();
  }, []);

  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);
  const [bondsmanInfo, setBondsmanInfo] = useState({
    originalClaimNumber: "",
    companyName: "",
    phoneNumber: "",
    email: "",
  });

  const openResolveModal = (claimId) => {
    console.log("Opening Resolve Modal for Claim ID:", claimId); // Debugging log
    setSelectedClaimId(claimId);
    setIsResolveModalOpen(true);
  };

  const closeResolveModal = () => {
    setIsResolveModalOpen(false);
  };

  useEffect(() => {
    console.log("Resolve Modal Open State:", isResolveModalOpen);
  }, [isResolveModalOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBondsmanInfo((prev) => ({ ...prev, [name]: value }));
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // Fetch support requests from the API
  const fetchSupportRequests = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/get-support-requests`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSupportRequests(data);
      } else {
        console.error("Failed to fetch support requests:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching support requests:", error);
    }
  };

  useEffect(() => {
    fetchSupportRequests();
  }, []);

  const pickUpSupportRequest = async (requestId) => {
    const userId = sessionStorage.getItem("user_id");

    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-pickup-support-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
          body: JSON.stringify({ request_id: requestId, user_id: userId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Support request picked up successfully:", data);

        // Update the local state to reflect the change
        setSupportRequests((prevRequests) =>
          prevRequests.map((request) =>
            request.request_id === requestId
              ? { ...request, picked_up_by: userId }
              : request
          )
        );

        // Trigger the flip animation
        triggerFlipAnimation(requestId);
      } else {
        console.error(
          "Failed to pick up the support request:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error picking up support request:", error);
    }
  };

  // Function to trigger the flip animation
  const triggerFlipAnimation = (requestId) => {
    const cardElement = document.getElementById(`support-card-${requestId}`);
    if (cardElement) {
      cardElement.classList.add("flip-card");
      setTimeout(() => {
        cardElement.classList.remove("flip-card");
      }, 600); // Duration of the flip animation
    }
  };

  const dropSupportRequest = async (requestId) => {
    try {
      const response = await fetch(
        `${apiBaseUrls.filer_dashboard}/filer-drop-support-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
          body: JSON.stringify({ request_id: requestId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Support request dropped successfully:", data);

        // Update the local state to reflect the change
        setSupportRequests((prevRequests) =>
          prevRequests.map((request) =>
            request.request_id === requestId
              ? { ...request, picked_up_by: null }
              : request
          )
        );

        alert("Support request dropped successfully.");
      } else {
        console.error(
          "Failed to drop the support request:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error dropping support request:", error);
    }
  };

  const fetchSidebarMetrics = async () => {
    try {
      const userId = sessionStorage.getItem("user_id");
      const jwtToken = sessionStorage.getItem("jwtToken");
      if (!userId || !jwtToken)
        throw new Error("Missing user ID or JWT token in session storage");

      const response = await fetch(
        `${apiBaseUrls.user_dashboard}/user-sidebar-claim-metrics?user_id=${userId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const metrics = await response.json();
      setSidebarMetrics({
        claimsFiled: metrics.claim_metrics.total_claims,
        claimsProcessed: metrics.claim_metrics.resolved_claims,
        outstandingClaims: metrics.claim_metrics.outstanding_claims,
        user_info: metrics.user_info,
      });
    } catch (error) {
      console.error("Error fetching sidebar metrics:", error);
    }
  };

  useEffect(() => {
    fetchSidebarMetrics();
  }, []);

  return (
    <div className={`filer-dashboard ${isSidebarOpen ? "sidebar-open" : ""}`}>
      {/* Main Dashboard Content */}
      <main>
        <section className="welcome-section">
          <h2>Welcome, {currentFiler}</h2>
          <p>
            Manage submitted claims, track progress, and resolve cases
            efficiently.
          </p>
        </section>
        <section className="metrics-section">
          <div className="metric-card">
            <h3>Claims Outstanding</h3>
            <p className="admin-metric-card">{metrics.outstandingClaims}</p>
          </div>
          <div className="metric-card">
            <h3>Claims in Progress</h3>
            <p className="admin-metric-card">{metrics.inProgressClaims}</p>
          </div>
          <div className="metric-card">
            <h3>Claims Resolved</h3>
            <p className="admin-metric-card">{metrics.resolvedClaims}</p>
          </div>
          <div className="metric-card">
            <h3>Total Claims Submitted</h3>
            <p className="admin-metric-card">{metrics.totalClaims}</p>
          </div>
        </section>

        {/* Picked-up Claims Section */}
        <section id="picked-up-claims" className="claims-section">
          <h2>Picked-Up Claims</h2>
          <div className="claims-list">
            {claims.map((claim) => (
              <div key={claim.claim_id} className="claim-item">
                {/* Notes Modal Trigger */}
                <div
                  className="claim-icon"
                  onClick={() => openNotesModal(claim.claim_id)} // Correct claim_id usage
                >
                  <img src={notesIcon} alt="Notes Icon" />
                </div>

                {/* Claim Details */}
                <div className="claim-details">
                  <h4>Claim #{claim.claim_id}</h4>{" "}
                  {/* Use claim.claim_id instead of claim.id */}
                  {/* Submitter Information */}
                  <p>
                    <strong>Submitted by:</strong> {claim.submitter.first_name}{" "}
                    {claim.submitter.last_name}
                  </p>
                  <p>
                    <strong>Email:</strong> {claim.email}
                  </p>
                  <p>
                    <strong>Phone Number:</strong> {claim.phone_number}
                  </p>
                  <p>
                    <strong>Driver's License:</strong> {claim.drivers_license}
                  </p>
                  {/* Claim Details */}
                  <p>
                    <strong>Date Filed:</strong> {claim.filed_date}
                  </p>
                  <p>
                    <strong>Original Claim Number:</strong> {claim.og_claim_num}
                  </p>
                  <p>
                    <strong>Company Name:</strong> {claim.company_name}
                  </p>
                  <p>
                    <strong>MC Number:</strong> {claim.mc_number}
                  </p>
                  <p>
                    <strong>Details:</strong> {claim.details}
                  </p>
                  <p className="claim-status">
                    <strong>Status:</strong> {claim.status}
                  </p>
                </div>

                {/* File List */}
                <div className="file-list">
                  <p>Uploaded Files:</p>
                  <ul>
                    {claim.files.map((file, index) => (
                      <li key={index}>
                        <a
                          href={file.file_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.file_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Claim Actions */}
                <div className="claim-actions">
                  {/* Message Modal Trigger */}
                  <button
                    className="btn-message-user"
                    onClick={() => openMessageModal(claim.claim_id)}
                  >
                    Claim Remarks
                  </button>

                  {/* Change Status Button & Dropdown */}
                  <button
                    className="btn-change-status"
                    onClick={() =>
                      setActiveDropdown(
                        activeDropdown === claim.claim_id
                          ? null
                          : claim.claim_id
                      )
                    } // Use claim.claim_id for dropdown logic
                  >
                    Change Status
                  </button>

                  {/* Dropdown Visible Based on Active State */}
                  {activeDropdown === claim.claim_id && ( // Ensure claim.claim_id is used here
                    <div className="status-dropdown active">
                      <ul>
                        {[
                          "Pending Review",
                          "Under Investigation",
                          "Resolved",
                          "Closed",
                        ].map((status) => (
                          <li
                            key={status}
                            onClick={() =>
                              changeClaimStatus(claim.claim_id, status)
                            }
                          >
                            {" "}
                            {/* Correct usage of claim.claim_id */}
                            {status}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Mark as Resolved Button */}
                  <button
                    className="btn-mark-completed"
                    onClick={() => {
                      console.log(
                        "Mark as Resolved button clicked for Claim ID:",
                        claim.claim_id
                      ); // Debugging log
                      openResolveModal(claim.claim_id);
                    }}
                  >
                    Mark as Resolved
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Outstanding Claims */}
        <section id="outstanding-claims" className="claims-section">
          <h2>Outstanding Claims</h2>
          <div className="toggle-show-more">
            <button className="btn-show-more" onClick={toggleShowMore}>
              {showMore ? "Show Less" : "Show More"}
            </button>
          </div>
          <div className="claims-grid">
            {outstandingClaims
              .slice(0, showMore ? outstandingClaims.length : claimsPerRow)
              .map((claim) => (
                <div key={claim.claim_id} className="claim-item">
                  <div
                    className="claim-icon"
                    onClick={() => openNotesModal(claim.claim_id)}
                  >
                    <img src={notesIcon} alt="Notes Icon" />
                  </div>

                  <div className="claim-details">
                    <h4>
                      <strong>
                        Claim #{claim.claim_id}{" "}
                        {/* Removed claim.claim_type as it's no longer relevant */}
                      </strong>
                    </h4>

                    {/* Submitter Information */}
                    <p>
                      <strong>Submitted By:</strong> {claim.first_name}{" "}
                      {claim.last_name}
                    </p>
                    <p>
                      <strong>Submitter Email:</strong> {claim.email}
                    </p>
                    <p>
                      <strong>Submitter Phone:</strong> {claim.phone_number}
                    </p>
                    <p>
                      <strong>Driver's License:</strong> {claim.drivers_license}
                    </p>

                    {/* Claim Metadata */}
                    <p>
                      <strong>Date Filed:</strong>{" "}
                      {new Date(claim.filed_date).toLocaleString()}
                    </p>
                    <p>
                      <strong>Original Claim Number:</strong>{" "}
                      {claim.og_claim_num}
                    </p>
                    <p>
                      <strong>Company Name:</strong> {claim.company_name}
                    </p>
                    <p>
                      <strong>MC Number:</strong> {claim.mc_number}
                    </p>
                    <p>
                      <strong>Details:</strong> {claim.details}
                    </p>
                    <p className="claim-status">
                      <strong>Status:</strong> {claim.status}
                    </p>
                  </div>

                  <div className="file-list">
                    <p>Uploaded Files:</p>
                    <ul>
                      {claim.files.map((file, index) => (
                        <li key={index}>
                          <a
                            href={file.file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.file_name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="claim-actions">
                    <button
                      className="btn-pickup-claim"
                      onClick={() => pickUpClaim(claim.claim_id)}
                    >
                      Pickup Claim
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </section>

        <section id="customer-support" className="customer-support-section">
          <h2>Customer Support Requests</h2>
          <div className="support-requests-list">
            {supportRequests.length > 0 ? (
              supportRequests.map((request) => (
                <div
                  key={request.request_id}
                  id={`support-card-${request.request_id}`}
                  className={`support-request-card ${
                    request.picked_up_by ? "picked-up" : ""
                  }`}
                >
                  <h4 className="support-request-header">
                    <span className="request-label">Support Request</span> #
                    {request.request_id}
                  </h4>
                  <p>
                    <strong>User:</strong> {request.first_name}{" "}
                    {request.last_name}
                  </p>
                  <p>
                    <strong>Email:</strong> {request.email}
                  </p>
                  <p>
                    <strong>Reason:</strong> {request.reason}
                  </p>
                  <p>
                    <strong>Severity:</strong> {request.severity}
                  </p>
                  <p>
                    <strong>Claim Number:</strong>{" "}
                    {request.claim_number || "N/A"}
                  </p>
                  <p className="support-message">{request.message}</p>
                  <p className="request-date">
                    <span className="date-label">Submitted on:</span>{" "}
                    {new Date(request.created_at).toLocaleString()}
                  </p>
                  <p>
                    <strong>Status:</strong> {request.status || "Pending"}
                  </p>
                  <p>
                    <strong>Picked Up By:</strong>{" "}
                    {request.picked_up_by || "Not yet assigned"}
                  </p>
                  <button
                    className="btn-respond-support"
                    onClick={() => pickUpSupportRequest(request.request_id)}
                    disabled={!!request.picked_up_by}
                  >
                    {request.picked_up_by ? "Picked Up" : "Pick Up"}
                  </button>
                  {request.picked_up_by ===
                    sessionStorage.getItem("user_id") && (
                    <button
                      className="btn-drop-support"
                      onClick={() => dropSupportRequest(request.request_id)}
                    >
                      Drop
                    </button>
                  )}
                </div>
              ))
            ) : (
              <p>No support requests available at this time.</p>
            )}
          </div>
        </section>

        {/* Resolved Claims Section */}
        <section id="resolved-claims" className="claims-section">
          <h2>Resolved Claims</h2>
          <div className="claims-list">
            {resolvedClaims.length > 0 ? (
              resolvedClaims.map((claim) => (
                <div key={claim.claim_id} className="claim-item">
                  <div className="claim-details">
                    <h4>Claim #{claim.claim_id}</h4>
                    <p>
                      <strong>Submitted by:</strong> {claim.user_id}
                    </p>
                    <p>
                      <strong>Date Filed:</strong>{" "}
                      {new Date(claim.filed_date).toLocaleString()}
                    </p>
                    <p>
                      <strong>Details:</strong> {claim.details}
                    </p>
                    <p className="claim-status">Status: {claim.status}</p>
                    <p>
                      <strong>Email:</strong> {claim.email}
                    </p>
                    <p>
                      <strong>Phone:</strong> {claim.phone_number}
                    </p>
                    <p>
                      <strong>Driver's License:</strong> {claim.drivers_license}
                    </p>
                    <p>
                      <strong>Original Claim Number:</strong>{" "}
                      {claim.og_claim_num}
                    </p>
                    <p>
                      <strong>Remarks:</strong> {claim.claimRemarks}
                    </p>
                    <p>
                      <strong>Last Modified:</strong>{" "}
                      {new Date(claim.date_last_modified).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>No resolved claims available at this time.</p>
            )}
          </div>
        </section>

        {/* Financials Section */}
        <section id="financials" className="financials-section">
          <h2>Financials</h2>
          <div className="financial-summary">
            <div className="income-breakdown">
              <h3>
                Monthly Income: <span id="monthly-income">$3,750.00</span>
              </h3>
              <h3>
                Weekly Income: <span id="weekly-income">$937.50</span>
              </h3>
              <h3>
                All-Time Income: <span id="all-time-income">$45,000.00</span>
              </h3>
            </div>
            <div className="income-details">
              <h4>Income Breakdown:</h4>
              <p>
                Total Claims: <span id="total-claims">180</span>
              </p>
              <p>Service Fee per Claim: $250</p>
              <p>
                Stripe Processing Fee (5%): <span id="stripe-fee">$12.50</span>{" "}
                per claim
              </p>
              <p>
                Net Income per Claim:{" "}
                <span id="net-income-per-claim">$237.50</span>
              </p>
            </div>
          </div>

          <div className="payment-disbursement">
            <h4>Revolving Payment Disbursement:</h4>
            <div className="payment-disbursement-grid">
              <div className="payment-item">
                <p>
                  <strong>Payment #1:</strong> Josh
                </p>
                <p>
                  <strong>Amount:</strong> $250.00
                </p>
                <p>
                  <strong>Status:</strong> Completed
                </p>
              </div>
              <div className="payment-item">
                <p>
                  <strong>Payment #2:</strong> Rory
                </p>
                <p>
                  <strong>Amount:</strong> $250.00
                </p>
                <p>
                  <strong>Status:</strong> Pending
                </p>
              </div>
              <div className="payment-item">
                <p>
                  <strong>Payment #3:</strong> Hasan
                </p>
                <p>
                  <strong>Amount:</strong> $250.00
                </p>
                <p>
                  <strong>Status:</strong> Pending
                </p>
              </div>
            </div>
          </div>

          <div className="financial-chart-section">
            <div className="chart-container">
              <canvas id="claims-chart" ref={claimsChartRef}></canvas>
            </div>
            <div className="chart-container">
              <canvas
                id="claims-filers-chart"
                ref={filersClaimsChartRef}
              ></canvas>
            </div>
            <div className="income-breakdown-filers">
              <h4>Income Breakdown by Filer:</h4>
              <p>Josh: $15,675.00</p>
              <p>Hasan: $12,350.00</p>
              <p>Rory: $8,925.00</p>
            </div>
          </div>
        </section>

        {isNotesModalOpen && (
          <div className={`notes-modal ${isNotesModalOpen ? "active" : ""}`}>
            <div className="notes-modal-content">
              <span className="close-notes-modal" onClick={closeNotesModal}>
                &times;
              </span>
              <h2 className="modal-title">
                Notes for Claim #{selectedClaimId}
              </h2>

              {/* Display saved notes */}
              <div className="saved-notes">
                {savedNotes.length > 0 ? (
                  savedNotes.map((note) => (
                    <div key={note.note_id} className="note-item">
                      <p className="note-text">{note.note_text}</p>
                      <p className="note-timestamp">
                        {new Date(note.created_at).toLocaleString()}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No notes available for this claim.</p>
                )}
              </div>

              <textarea
                className="note-input"
                placeholder="Write your note here..."
                value={noteInput}
                onChange={(e) => setNoteInput(e.target.value)}
              ></textarea>
              <button className="save-note-btn" onClick={saveNote}>
                Save Note
              </button>
            </div>
          </div>
        )}

        {/* Message Modal */}
        {isMessageModalOpen && selectedClaimId && (
          <div className="message-modal">
            <div className="message-modal-content">
              <span className="close-message-modal" onClick={closeMessageModal}>
                &times;
              </span>
              <h2 className="modal-title">
                Message User for Claim #{selectedClaimId}
              </h2>

              {/* Display chat history */}
              <div className="chat-history">
                {chatHistory.map((chat, index) => (
                  <div
                    key={chat.remark_id || index}
                    className={`chat-message ${
                      chat.user_id === sessionStorage.getItem("user_id")
                        ? "me"
                        : "user"
                    }`}
                  >
                    <p className="message-sender">
                      {chat.user_id === sessionStorage.getItem("user_id")
                        ? "You"
                        : `User ${chat.user_id}`}
                      :
                    </p>
                    <p className="message-content">{chat.remark_text}</p>
                    <p className="message-timestamp">
                      {new Date(chat.created_at).toLocaleString()}
                    </p>
                  </div>
                ))}
              </div>

              {/* Input for new remarks */}
              <div className="message-input-container">
                <textarea
                  className="message-input"
                  placeholder="Type your remark here..."
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                ></textarea>
                <button className="send-message-btn" onClick={sendMessage}>
                  Send
                </button>
              </div>
            </div>
          </div>
        )}

        {isResolveModalOpen && (
          <div
            className={`resolve-modal ${isResolveModalOpen ? "active" : ""}`}
          >
            <div className="resolve-modal-content">
              <span className="close-resolve-modal" onClick={closeResolveModal}>
                &times;
              </span>
              <h2 className="resolve-modal-title">
                Resolve Claim and Add Bondsman Details
              </h2>
              <div className="modal-field">
                <label>Original Claim Number</label>
                <input
                  type="text"
                  name="originalClaimNumber"
                  value={bondsmanInfo.originalClaimNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="modal-field">
                <label>Bondsman/Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  value={bondsmanInfo.companyName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="modal-field">
                <label>Telephone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={bondsmanInfo.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="modal-field">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={bondsmanInfo.email}
                  onChange={handleInputChange}
                />
              </div>
              <button className="resolve-btn" onClick={submitResolution}>
                Submit to User & Resolve
              </button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default FilerDashboard;
