import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import "./App.css";
import Home from "./components/Home";
import FilerDashboard from "./components/FilerDashboard";
import Dashboard from "./components/Dashboard";
import CustomerSupport from "./components/CustomerSupport";
import FAQ from "./components/FAQ";
import Login from "./components/Login";
import TermsAndConditions from "./components/TermsAndConditions";
import ProtectedRoute from "./components/ProtectedRoute";
import useAutoSignOut from "./components/UseAutoSignOut";
import Header from "./components/Header";
import Footer from "./components/Footer";

const SEOMetadata = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const getMetadata = () => {
    const baseMetadata = {
      title: "Crazy Claims | Professional Freight & Cargo Claim Filing for Truckers - $250 Flat Rate",
      description: "Expert freight and cargo claim filing service for truckers, owner-operators & carriers. Fast recovery of unpaid invoices, damage claims & cargo claims. 24/7 support with proven results.",
      keywords: "freight claims, cargo damage claims, truck driver claims, owner operator claims, unpaid freight bills, transportation claims, logistics claims, carrier claims, bill of lading claims, load claims, trucking invoice recovery, detention claims, lumper claims"
    };

    switch (currentPath) {
      case '/':
        return baseMetadata;
      case '/FAQ':
        return {
          title: "Freight Claim Filing FAQ | Common Questions for Truckers - Crazy Claims",
          description: "Get answers to common freight claim filing questions. Learn how we help truckers and carriers recover unpaid invoices, handle cargo claims, and resolve disputes.",
          keywords: "freight claim FAQ, cargo claim help, truck driver claims guide, claim filing process, freight bill disputes, carrier claim support"
        };
      case '/CustomerSupport':
        return {
          title: "24/7 Trucking Claims Support | Expert Help for Carriers - Crazy Claims",
          description: "Round-the-clock support for truckers and carriers filing freight claims. Expert guidance on cargo claims, invoice recovery, and dispute resolution.",
          keywords: "trucking support, freight claim help, cargo claim assistance, carrier support, claims guidance, 24/7 claim support"
        };
      case '/Login':
        return {
          title: "Secure Login | Track Your Freight Claims - Crazy Claims",
          description: "Login to manage your freight and cargo claims. Track progress, view updates, and communicate with our claim filing experts.",
          keywords: "freight claim login, cargo claim tracking, claim management, carrier login, trucker portal"
        };
      default:
        return baseMetadata;
    }
  };

  const metadata = getMetadata();
  const canonicalUrl = `https://crazyclaims.com${currentPath}`;

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />
      <link rel="canonical" href={canonicalUrl} />
      
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "Crazy Claims",
            "description": "Professional freight claim filing service specializing in helping truckers, owner-operators, and carriers recover unpaid invoices and resolve cargo claims.",
            "priceRange": "$250",
            "audience": {
              "@type": "Audience",
              "audienceType": ["Truck Drivers", "Owner Operators", "Freight Carriers", "Logistics Companies"]
            },
            "knowsAbout": [
              "Freight Claims",
              "Cargo Claims",
              "Detention Claims",
              "Lumper Claims",
              "Invoice Recovery",
              "Bill of Lading Claims",
              "Transportation Claims"
            ],
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Freight Claim Services",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Professional Claim Filing",
                    "description": "Expert freight claim filing service with 24/7 support",
                    "price": "250",
                    "priceCurrency": "USD"
                  }
                }
              ]
            },
            "slogan": "Professional Freight Claim Filing Made Easy",
            "areaServed": "United States",
            "availableLanguage": "English",
            "openingHours": "Mo-Su 00:00-23:59",
            "paymentAccepted": ["Credit Card", "PayPal"],
            "url": "https://crazyclaims.com"
          }
        `}
      </script>
    </Helmet>
  );
};

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="app-container">
          <SEOMetadata />
          <AutoSignOutWrapper />
          <HeaderWrapper />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/FilerDashboard"
                element={
                  <ProtectedRoute requiredRole="admin">
                    <FilerDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Dashboard"
                element={
                  <ProtectedRoute requiredRole="customer">
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="/CustomerSupport" element={<CustomerSupport />} />
              <Route path="/FAQ" element={<FAQ />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

const AutoSignOutWrapper = () => {
  useAutoSignOut();
  return null;
};

const HeaderWrapper = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    setIsLoggedIn(jwtToken && isTokenValid(jwtToken));

    function isTokenValid(jwtToken) {
      if (!jwtToken) return false;
      const payloadBase64 = jwtToken.split(".")[1];
      const decodedPayload = JSON.parse(atob(payloadBase64));
      const expirationTime = decodedPayload.exp * 1000;
      const currentTime = Date.now();
      return expirationTime > currentTime;
    }
  }, []);

  const handleLoginOrDashboardClick = () => {
    if (isLoggedIn) {
      window.location.href = "/Dashboard";
    } else {
      window.location.href = "/Login";
    }
  };

  return (
    <Header
      isLoggedIn={isLoggedIn}
      onLoginOrDashboardClick={handleLoginOrDashboardClick}
    />
  );
};

export default App;