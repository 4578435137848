import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import "../stylings/Header.css";
import accountIcon from "../icons/crazyClaims_Account_Icon.png";
import crazyClaimsLogo2 from "../logos/crazyClaims_Rough_Logo_2.png";
import apiBaseUrls from "../config";

// Sidebar component for reuse in Header and other components
const Sidebar = ({
  isSidebarOpen,
  toggleSidebar,
  sidebarMetrics,
  handleLogout,
  userProfileImage,
  onLoginOrDashboardClick,
  isLoggedIn,
  navigate,
}) => {
  const navigateToHomeSection = (sectionId) => {
    navigate(`/#${sectionId}`);
    toggleSidebar();
  };

  const handleDashboardNavigation = () => {
    const userRole = sessionStorage.getItem("userRole") || "";
    if (userRole.includes("admin")) {
      navigate("/FilerDashboard");
    } else {
      navigate("/Dashboard");
    }
    toggleSidebar();
  };

  const handleFileClaimClick = () => {
    if (isLoggedIn) {
      navigate("/Dashboard", { state: { openClaimPopup: true } });
    } else {
      navigate("/Login", { state: { showAlert: true } });
    }
    toggleSidebar();
  };

  const handleLoginClick = () => {
    navigate("/login");
    toggleSidebar();
  };

  const handleFAQClick = () => {
    navigate("/faq");
    toggleSidebar();
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? "active" : ""}`}>
      <div className="sidebar-header">
        <button className="close-sidebar-btn" onClick={toggleSidebar}>
          &times;
        </button>
        <img
          src={userProfileImage}
          alt="User Profile"
          className="sidebar-profile-img"
        />
        {isLoggedIn && (
          <>
            <h2>
              {sidebarMetrics.user_info.first_name}{" "}
              {sidebarMetrics.user_info.last_name}
            </h2>
            <p className="sidebar-email">{sidebarMetrics.user_info.email}</p>
          </>
        )}
      </div>

      <div className="sidebar-content">
        <ul className="sidebar-nav">
          <li>
            <a
              onClick={handleFileClaimClick}
              className="file-claim-link"
              style={{
                color: "#f9d342",
                fontWeight: "bold",
                fontSize: "1.3rem",
                marginBottom: "20px",
                display: "block",
                borderBottom: "2px solid #f9d342",
                paddingBottom: "10px",
              }}
            >
              File Claim
            </a>
          </li>
          <li>
            <a onClick={() => navigateToHomeSection("about")}>About</a>
          </li>
          <li>
            <a onClick={() => navigateToHomeSection("services")}>Services</a>
          </li>
          <li>
            <a onClick={() => navigateToHomeSection("pricing")}>Pricing</a>
          </li>
          <li>
            <a onClick={() => navigateToHomeSection("contact")}>Contact</a>
          </li>
          <li>
            <a onClick={handleFAQClick}>FAQ</a>
          </li>
          {isLoggedIn && (
            <li>
              <a onClick={handleDashboardNavigation}>Dashboard</a>
            </li>
          )}
        </ul>
      </div>

      <div className="sidebar-footer">
        {isLoggedIn ? (
          <button className="btn-logout" onClick={handleLogout}>
            Logout
          </button>
        ) : (
          <button className="btn-login" onClick={handleLoginClick}>
            Login
          </button>
        )}
      </div>

      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
    </div>
  );
};

const Header = ({ onLoginOrDashboardClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarMetrics, setSidebarMetrics] = useState({
    claimsFiled: 0,
    claimsProcessed: 0,
    outstandingClaims: 0,
    user_info: { first_name: "", last_name: "", email: "" },
  });
  const [userProfileImage, setUserProfileImage] = useState(accountIcon);

  // Set isLoggedIn based on sessionStorage directly
  const isLoggedIn = !!sessionStorage.getItem("jwtToken");

  // Toggle sidebar visibility
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  // Fetch sidebar metrics if logged in
  const fetchSidebarMetrics = async () => {
    try {
      const userId = sessionStorage.getItem("user_id");
      const jwtToken = sessionStorage.getItem("jwtToken");
      if (!userId || !jwtToken) throw new Error("Missing user ID or JWT token");

      const response = await fetch(
        `${apiBaseUrls.user_dashboard}/user-sidebar-claim-metrics?user_id=${userId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const metrics = await response.json();
      setSidebarMetrics({
        claimsFiled: metrics.claim_metrics.total_claims,
        claimsProcessed: metrics.claim_metrics.resolved_claims,
        outstandingClaims: metrics.claim_metrics.outstanding_claims,
        user_info: metrics.user_info,
      });
      setUserProfileImage(metrics.user_info.profile_image || accountIcon);
    } catch (error) {
      console.error("Error fetching sidebar metrics:", error);
    }
  };

  // Smooth scroll to hash location on Home page
  useEffect(() => {
    if (location.pathname === "/" && location.hash) {
      const sectionId = location.hash.replace("#", "");
      const element = document.getElementById(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 10,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  // Fetch metrics if logged in and sidebar metrics need updating
  useEffect(() => {
    if (isLoggedIn) {
      fetchSidebarMetrics();
    }
  }, [isLoggedIn]);

  // Handle user logout
  const handleLogout = async () => {
    try {
      await signOut();
      sessionStorage.clear();
      alert("Logging out...");
      setSidebarOpen(false);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <>
      <header className="header">
        <div className="container header-flex">
          <div className="logo-container" onClick={() => navigate("/")}>
            <img
              src={crazyClaimsLogo2}
              alt="Crazy Claims Logo"
              className="logo-image"
            />
            <h1>Crazy Claims</h1>
          </div>

          <nav className="navbar hide-on-mobile">
            <ul>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
              <li>
                <a href="/faq">FAQ</a>
              </li>
            </ul>
          </nav>

          <div className="account-icon-container">
            <p className="login-text" onClick={onLoginOrDashboardClick}>
              {isLoggedIn ? "Dashboard" : "Login"}
            </p>
            <img
              src={userProfileImage}
              alt="Account Icon"
              className="account-icon"
              onClick={toggleSidebar}
            />
          </div>
        </div>
      </header>

      {/* Sidebar Component */}
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        sidebarMetrics={sidebarMetrics}
        handleLogout={handleLogout}
        userProfileImage={userProfileImage}
        onLoginOrDashboardClick={onLoginOrDashboardClick}
        isLoggedIn={isLoggedIn}
        navigate={navigate}
      />
    </>
  );
};

export default Header;
export { Sidebar };
