import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import logo from "../logos/crazyClaims_Rough_Logo_1.png";
import accountIcon from "../icons/crazyClaims_Account_Icon.png";
import "../stylings/FAQ.css"; // Ensure your FAQ styles are in this file
import Loading from "./Loading"; // Import the Loading component
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate a loading delay (like fetching user status)
    setTimeout(() => {
      const jwtToken = sessionStorage.getItem("jwtToken");
      if (jwtToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false); // Stop loading after checking login state
    }, 1000); // Simulated delay for loading
  }, []);

  useEffect(() => {
    // Handle smooth scrolling for hash links
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 10, // Adjust the offset as needed
            behavior: "smooth",
          });
        }
      }
    };

    // Handle initial hash on page load
    handleHashChange();

    // Listen for hash changes after the page is loaded
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const handleLoginOrDashboardClick = () => {
    if (isLoggedIn) {
      // Redirect to the Dashboard page
      navigate("/Dashboard");
    } else {
      // Redirect to the Login page
      navigate("/Login");
    }
  };

  // Function to navigate to Home sections
  const navigateToHomeSection = (sectionId) => {
    navigate("/"); // Navigate to the Home page
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 10,
          behavior: "smooth",
        });
      }
    }, 100); // Set timeout to ensure page fully renders before scrolling
  };

  // Show the loading spinner while the page is fetching/loading data
  if (loading) {
    return <Loading />; // Show loading component while loading is true
  }

  return (
    <>
      <Helmet>
        <title>Freight Claim Filing FAQ | Crazy Claims - Professional $250 Claim Service</title>
        <meta name="description" content="Get answers about professional freight claim filing services. Learn about our $250 flat-fee claim filing process, document requirements, and 24/7 support options for truckers and carriers." />
        <meta name="keywords" content="freight claim FAQ, cargo claim filing, trucking claims help, transportation claim process, broker payment claims, carrier claim assistance, logistics claim filing, freight broker claims, unpaid invoice claims, truck driver claims, owner operator claims" />
        <link rel="canonical" href="https://crazyclaims.com/faq" />
        
        <meta property="og:title" content="Freight Claim Filing FAQ | Professional Claims Service" />
        <meta property="og:description" content="Professional freight claim filing service FAQ. Learn about our $250 flat-fee claim process and support options." />
        <meta property="og:url" content="https://crazyclaims.com/faq" />
        <meta property="og:type" content="website" />
        
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Crazy Claims" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What is Crazy Claims?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Crazy Claims is a professional freight claim filing service helping truckers and carriers recover unpaid invoices through proper claim filing and management. We handle all paperwork and filing, allowing you to focus on your work while we help reclaim your money."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How much does the freight claim filing service cost?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our service costs a flat fee of $250 per claim, covering comprehensive claim preparation, filing, and management through our Customer Dashboard."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is the process for filing a claim?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The process involves: 1. Creating an account and logging in 2. Accessing the Claim Filing Section 3. Submitting required documents (invoice, bill of lading, rate confirmation) 4. Entering claim details 5. Accepting Terms and Conditions 6. Completing $250 payment via Stripe or PayPal"
                  }
                },
                {
                  "@type": "Question",
                  "name": "What happens if I need to amend my claim after submission?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Contact our support team through the dashboard support form with your email, claim number, and amendment details. Our team will review and process your request promptly."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How is my data handled?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Personal information is securely deleted after 30 days. Claim-related data is securely archived for 7 years to comply with industry standards and support follow-up needs."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What payment methods do you accept?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We accept secure payments through Stripe and PayPal. Credit card details are handled with the highest level of security and are not stored on our servers."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is there a guarantee that I will receive a payout?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "There is no guarantee of payout. The $250 fee covers professional services for preparing and managing your claim. Final payout decisions rest with the insured company."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can I file multiple claims at once?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, you can file multiple claims. Each claim requires a separate $250 fee and will be processed individually."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What types of unpaid invoices can I file a claim for?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We specialize in claims against brokers with valid broker's licenses who have not fulfilled payment obligations for freight services."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How will I know if my claim is successful?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You'll receive regular updates through your Customer Dashboard and email notifications. Successful claims include necessary information and next steps for receiving your payout."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What support options are available?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "24/7 support via dashboard support form, email (support@crazyclaims.com), and phone (1-888-382-7837 or 1-888-400-2163). Our team is always ready to assist you."
                  }
                }
              ]
            }
          `}
        </script>
      </Helmet>

        <div className="faq-page" itemScope itemType="https://schema.org/FAQPage">

        <section id="faq" className="faq-section">
          <div className="container">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-content">
              <div className="faq-item" id="service-info" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <h3>What is Crazy Claims?</h3>
                <p>
                  Crazy Claims is committed to making the process of filing claims
                  on unpaid invoices as simple and stress-free as possible. Our
                  goal is to support individuals and businesses who deserve to be
                  paid for their hard work. We take care of the paperwork and
                  filing, allowing you to focus on what you do best while we work
                  tirelessly to help you reclaim the money that’s rightfully yours.
                </p>
                <p>
                  Our service specializes in filing claims against brokers who have
                  not fulfilled their payment obligations. To proceed with a claim,
                  it must involve a brokerage with a valid broker's license,
                  ensuring that we can hold the appropriate parties accountable for
                  any unpaid invoices.
                </p>
              </div>

              <div className="faq-item">
                <h3>How much does the service cost?</h3>
                <p className="important">
                  Our service is available for a flat fee of $250 per claim. This fee covers the comprehensive work involved in preparing, filing, and managing your claim, including regular updates on your claim status through the Crazy Claims Customer Dashboard. You’ll receive progress updates from the filer handling your claim, including any remarks or notes pertinent to the claim process. However, please note that this fee does not guarantee a payout, as the final decision lies with the company insured on the claim. If the claim is denied, or if the insured company does not accept it, we will provide all relevant information, including any contact details necessary for you to pursue further action. The fee is non-refundable, regardless of the claim outcome, and by using our service, you acknowledge and accept these terms.
                </p>
              </div>


              <div className="faq-item" id="file-claim-info">
                <h3>What is the process for filing a claim?</h3>
                <p>
                  The claim filing process on Crazy Claims is designed to be straightforward and user-friendly. Here’s an overview of how to get started:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <strong>Step 1: Create an Account and Log In</strong> – Start by creating an account on Crazy Claims. Once registered, log in to access your personalized dashboard.
                  </li>
                  <li>
                    <strong>Step 2: Access the Claim Filing Section</strong> – Navigate to your user dashboard and select "File a New Claim." You’ll be prompted to provide specific details and upload required documents.
                  </li>
                  <li>
                    <strong>Step 3: Submit Required Documents</strong> – To proceed, upload the original invoice, bill of lading, and rate confirmation. You may also attach any additional supporting documents, such as a notice of assignment from a factoring company.
                  </li>
                  <li>
                    <strong>Step 4: Enter Claim Details</strong> – Provide the necessary information for your claim, including the company name, MC number (if available), and any additional details. Please ensure that the individual submitting the claim is the one named on the invoice, as we cannot process claims filed on behalf of another person.
                  </li>
                  <li>
                    <strong>Step 5: Accept Terms and Conditions</strong> – Before submitting, review and agree to our Terms and Conditions. This step acknowledges the $250 non-refundable fee and that while we facilitate the claim process, payout decisions rest with the insured party.
                  </li>
                  <li>
                    <strong>Step 6: Complete Payment</strong> – After entering your claim information, proceed with payment via Stripe or PayPal. Upon successful payment, you’ll receive a confirmation email, and the claim status will be visible in your dashboard.
                  </li>
                </ul>
                <p>
                  Once filed, you can monitor your claim’s progress in the “Filed Claims” section of your dashboard. You’ll receive updates, remarks, and any additional instructions directly on the claim card. If a claim is denied or not accepted by the insured company, we will provide any relevant details and contact information for further assistance. For any questions or assistance, please contact our support team from your dashboard.
                </p>
              </div>


              <div className="faq-item" id="claim-assistance-info">
                <h3>What happens if I need to amend my claim after submission?</h3>
                <p>
                  If you need to make changes to your claim after submission, please reach out to our support team as soon as possible through the support form located at the bottom of your user dashboard. To help us expedite your request, please include the following details:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li><strong>Your Email:</strong> The email associated with your account to ensure we can quickly locate your claim.</li>
                  <li><strong>Reason for Message:</strong> Select “General Inquiry” or the most relevant option to help us understand the nature of your request.</li>
                  <li><strong>Severity:</strong> Choose the level of urgency (e.g., “High” or “Urgent”) to prioritize your request.</li>
                  <li><strong>Claim Number:</strong> Enter your claim number to specify the claim you wish to amend.</li>
                  <li><strong>Message:</strong> Provide a detailed description of the changes or corrections needed for your claim.</li>
                </ul>
                <p>
                  Our team will review your request and do our best to accommodate the amendments, though please note that updates to an existing claim may result in additional processing time. Once we receive your submission, you will see an on-screen confirmation, and our support team will follow up with any next steps or clarifications needed.
                </p>
              </div>


              <div className="faq-item">
                <h3>How is my data handled?</h3>
                <p>
                  We prioritize your privacy and data security. Driver’s license information, address, and phone number details are securely deleted <strong>30 days</strong> after submission. Key data related to your claim, such as claim details and relevant claim data, are retained and securely archived for <strong>7 years</strong> to comply with industry best practices and support any necessary follow-up or auditing. For more information, please review our <a href="/terms-and-conditions" className="terms-link">Terms and Conditions</a>.
                </p>
              </div>

              <div className="faq-item">
                <h3>What payment methods do you accept?</h3>
                <p>
                  We accept payments via Stripe and PayPal for secure and convenient payment processing. Stripe ensures that your credit card details are handled with the highest level of security, and we do not store any payment information on our servers. If you prefer, you can also choose PayPal to complete your transaction, offering additional flexibility in payment options.
                </p>
              </div>


              <div className="faq-item">
                <h3>Is there a guarantee that I will receive a payout?</h3>
                <p className="important">
                  There is no guarantee of a payout. The $250 fee covers the professional time, effort, and resources involved in preparing, submitting, and managing the claim process on your behalf. This includes regular updates and remarks available on your claim card in the Crazy Claims Customer Dashboard. However, the final decision lies with the company insured on the claim, and we cannot influence their determination. In the event that the claim is denied by Crazy Claims or not accepted by the company insured, we will provide you with all the details and contact information available for you to follow up directly. By using our service, you acknowledge and recognize that while we do our utmost to facilitate the process, we cannot ensure a payout.
                </p>
              </div>


              <div className="faq-item">
                <h3>Can I file multiple claims at once?</h3>
                <p>
                  Yes, you can file multiple claims. However, each claim will be
                  processed separately, and a $250 fee will apply to each
                  individual claim.
                </p>
              </div>

              <div className="faq-item">
                <h3>What types of unpaid invoices can I file a claim for?</h3>
                <p>
                  Crazy Claims specializes in assisting with claims on unpaid
                  invoices. If you’re unsure whether your invoice qualifies, feel
                  free to reach out to our support team for guidance.
                </p>
              </div>

              <div className="faq-item">
                <h3>How will I know if my claim is successful?</h3>
                <p>
                  You will receive regular updates on the status and remarks of your claim through the Crazy Claims Customer Dashboard, specifically on the claim card associated with the claim you filed. The filer handling your claim will post updates there to keep you informed. Additionally, if your claim is successfully processed and filed, you will receive an email containing all necessary information and next steps, including details on the company to contact to receive your payout. This information will also be available on your claim card within the dashboard.
                </p>
              </div>


              <div className="faq-item">
                <h3>What should I do if my claim is denied?</h3>
                <p>
                  If your claim is denied, you will receive a detailed explanation on your Crazy Claims Customer Dashboard and by email. In cases where Crazy Claims completes the claim filing process but the company insured on the claim does not accept it, we will provide all available information regarding the reason for denial and, where possible, the contact details of the company to whom you can appeal directly. While Crazy Claims will do our utmost to support you with advice on any potential next steps, please note that our influence is limited to the filing process, and the final decision rests with the company holding the insurance on your claim.
                </p>
              </div>


              <div className="faq-item">
                <h3>Is there a refund policy?</h3>
                <p>
                  The $250 fee is non-refundable. This fee covers the comprehensive administrative, documentation, and processing work required to file and manage your claim. Our service includes guided claim preparation, document verification, regular updates on your claim status through the Crazy Claims Customer Dashboard, and direct support from our team to ensure your claim is filed accurately and on time. While we strive to provide the highest quality service and increase your chances of a successful claim, the final decision on payouts ultimately rests with the company insured on the claim. By using our service, you acknowledge and accept these terms. If you have questions or require further assistance during the process, please reach out to our support team via your dashboard.
                </p>
              </div>

              <div className="faq-item" id="support-info">
                <h3>What support options are available?</h3>
                <p>
                  Our dedicated support team is available 24/7 to assist you with any questions, issues, or concerns you may have. We offer multiple ways to reach us, ensuring you get the help you need in the way that’s most convenient for you. 
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li><strong>Support Form:</strong> Access our support form directly from your dashboard to submit your query or issue. This is often the fastest way to get in touch, and you’ll receive updates directly in your dashboard.</li>
                  <li><strong>Email Support:</strong> For any questions or assistance, you can email us at <a href="mailto:support@crazyclaims.com">support@crazyclaims.com</a> or <a href="mailto:contact@crazyclaims.com">contact@crazyclaims.com</a>. Our team will respond promptly to address your concerns.</li>
                  <li><strong>Phone Support:</strong> You can reach our support team by phone at our main line, <strong>1-888-382-7837</strong>, or our alternate line, <strong>1-888-400-2163</strong>. Whether you prefer to call directly or need quick assistance, we are here to help.</li>
                </ul>
                <p>
                  We are committed to making your experience with Crazy Claims seamless, and we work hard to resolve any issues promptly. Feel free to reach out anytime, and our team will be happy to assist you.
                </p>
              </div>

              {/* Terms and Conditions Link */}
              <div className="terms-and-conditions-link">
                <p>
                  For more information, please review our{" "}
                  <a
                    href="/terms-and-conditions"
                    className="terms-link"
                    style={{
                      fontWeight: "bold",
                      color: "#0077b6",
                      textDecoration: "underline",
                    }}
                  >
                    Terms and Conditions
                  </a>
                  .
                </p>
              </div>

            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FAQ;
