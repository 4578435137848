import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import "../stylings/Home.css";
import Header from "./Header";
import aboutUs_IMG from "../imgs/aboutUs_IMG.png";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";

function isTokenValid(jwtToken) {
 if (!jwtToken) return false;
 const payloadBase64 = jwtToken.split('.')[1];
 const decodedPayload = JSON.parse(atob(payloadBase64));
 const expirationTime = decodedPayload.exp * 1000;
 const currentTime = Date.now();
 return expirationTime > currentTime;
}

function Home() {
 const [isLoggedIn, setIsLoggedIn] = useState(false);
 const [loading, setLoading] = useState(true);
 const [isMuted, setIsMuted] = useState(true);
 const [isPlaying, setIsPlaying] = useState(false);
 const [videoReady, setVideoReady] = useState(false);
 const [isVideoLoading, setIsVideoLoading] = useState(true);
 const videoRef = useRef(null);
 const navigate = useNavigate();

 useEffect(() => {
   const initializeVideo = async () => {
     if (videoRef.current) {
       try {
         await videoRef.current.play();
         setVideoReady(true);
         setIsPlaying(true);
       } catch (error) {
         console.log("Autoplay prevented:", error);
         videoRef.current.muted = true;
         videoRef.current.play();
       }
       setIsVideoLoading(false);
     }
   };

   initializeVideo();
 }, []);

 const handleVideoLoad = () => {
   if (videoRef.current) {
     videoRef.current.play()
       .then(() => {
         setVideoReady(true);
         setIsVideoLoading(false);
       })
       .catch((error) => {
         console.log("Autoplay prevented:", error);
         videoRef.current.muted = true;
         videoRef.current.play();
         setIsVideoLoading(false);
       });
   }
 };

 const handlePlayClick = () => {
   if (videoRef.current) {
     if (!isPlaying) {
       videoRef.current.play();
       videoRef.current.muted = false;
     }
     setIsMuted(!isMuted);
     setIsPlaying(true);
   }
 };

 useEffect(() => {
   setTimeout(() => {
     const jwtToken = sessionStorage.getItem("jwtToken");
     console.log("JWT Token:", jwtToken);

     if (isTokenValid(jwtToken)) {
       setIsLoggedIn(true);
       console.log("User is logged in");
     } else {
       sessionStorage.removeItem("jwtToken");
       setIsLoggedIn(false);
       console.log("User is not logged in or token is invalid");
     }

     setLoading(false);
   }, 1000);

   const handleSmoothScroll = (e) => {
     e.preventDefault();
     const targetId = e.currentTarget.getAttribute("href").substring(1);
     const targetElement = document.getElementById(targetId);
     if (targetElement) {
       window.scrollTo({
         top: targetElement.offsetTop - 10,
         behavior: "smooth",
       });
     }
   };

   const anchors = document.querySelectorAll('a[href^="#"]');
   anchors.forEach((anchor) => {
     anchor.addEventListener("click", handleSmoothScroll);
   });

   return () => {
     anchors.forEach((anchor) => {
       anchor.removeEventListener("click", handleSmoothScroll);
     });
   };
 }, []);

 const handleLoginOrDashboardClick = () => {
   if (isLoggedIn) {
     navigate("/Dashboard");
   } else {
     navigate("/Login");
   }
 };

 const handleFileClaimClick = () => {
   if (!isLoggedIn) {
     navigate("/Login", { state: { showAlert: true } });
   } else {
     navigate("/Dashboard", { state: { openClaimPopup: true } });
   }
 };

 if (loading) {
   return <Loading />;
 }

 return (
   <>
     <Helmet>
       <title>Crazy Claims | Professional Freight Claim Filing Service - $250 Flat Fee</title>
       <meta name="description" content="File freight claims easily with Crazy Claims. Professional claim filing service for truckers and carriers. Get your unpaid invoices resolved for a flat fee of $250. 24/7 expert support available." />
       <meta name="keywords" content="freight claims, cargo claims, trucking claims, claim filing service, transportation claims, logistics claims, unpaid invoice recovery, broker payment claims" />
       <link rel="canonical" href="https://crazyclaims.com" />
       
       {/* Open Graph Meta Tags */}
       <meta property="og:title" content="Professional Freight Claim Filing Service | Crazy Claims" />
       <meta property="og:description" content="File your freight claims professionally with Crazy Claims. Flat fee of $250 per claim. Expert claim filing service for truckers and carriers." />
       <meta property="og:url" content="https://crazyclaims.com" />
       <meta property="og:type" content="website" />
     </Helmet>

     <div itemScope itemType="https://schema.org/WebPage">
       <main>
         <section id="hero" className="hero" aria-label="hero-section">
           <div className="hero-top">
             <div className="hero-text-box" itemScope itemType="https://schema.org/Service">
               <h2 itemProp="name">File a Claim Today for Only $250</h2>
               <p itemProp="description">
                 Don't let unpaid invoices go unresolved. File your claim now by uploading a bill of lading, original invoice, and rate confirmation to reclaim what's rightfully yours. Time is money—act today!
               </p>
               <div className="hero-buttons">
                 <button className="btn-primary" onClick={handleFileClaimClick}>
                   File Claim
                 </button>
                 <button
                   className="btn-secondary"
                   onClick={() => (window.location.href = "/faq#service-info")}
                 >
                   Learn More
                 </button>
               </div>
             </div>
             <div className="hero-video-container">
               {isVideoLoading && (
                 <div className="video-loading">
                   <span>Loading video...</span>
                 </div>
               )}
               <video 
                 ref={videoRef}
                 autoPlay
                 playsInline
                 loop
                 controls
                 className="hero-video"
                 itemProp="video"
                 onLoadedData={handleVideoLoad}
                 muted={!videoReady}
               >
                 <source 
                   src="https://crazyclaims-img-bucket.s3.us-east-1.amazonaws.com/videos/crazyClaimsIntroVideo.mp4" 
                   type="video/mp4" 
                 />
                 Your browser does not support the video tag.
               </video>
               {!isPlaying && !isVideoLoading && (
                 <div className="video-overlay" onClick={handlePlayClick}>
                   <button className="play-button" aria-label="Play video">
                     <svg 
                       width="48" 
                       height="48" 
                       viewBox="0 0 24 24" 
                       fill="none" 
                       stroke="currentColor" 
                       strokeWidth="2" 
                       strokeLinecap="round" 
                       strokeLinejoin="round"
                     >
                       <circle cx="12" cy="12" r="10" />
                       <polygon points="10 8 16 12 10 16 10 8" />
                     </svg>
                   </button>
                   <span className="overlay-text">Click to play with sound</span>
                 </div>
               )}
             </div>
           </div>
           <div className="hero-bottom">
             <p itemProp="slogan">"Don't leave money on the table. Claim what's yours today."</p>
           </div>
         </section>

         <section id="services" className="services" aria-label="services-section">
           <div className="container">
             <h2>Our Services</h2>
             <div className="service-cards" itemScope itemType="https://schema.org/Service">
               <div className="card" itemProp="hasOfferCatalog">
                 <h3>File a Claim</h3>
                 <p itemProp="description">
                   For just $250, you can have your claim expertly filed by
                   professionals who understand the intricacies of the system.
                   Reclaim what's yours without the hassle.
                 </p>
                 <button
                   className="btn-learn-more"
                   onClick={() => navigate("/faq#file-claim-info")}
                 >
                   Learn More
                 </button>
               </div>
               <div className="card" itemProp="hasOfferCatalog">
                 <h3>Claim Assistance</h3>
                 <p itemProp="description">
                   Navigate the claim process with ease. Our experts provide
                   step-by-step guidance to ensure your claim is processed
                   efficiently, leaving no stone unturned.
                 </p>
                 <button
                   className="btn-learn-more"
                   onClick={() => navigate("/faq#claim-assistance-info")}
                 >
                   Learn More
                 </button>
               </div>
               <div className="card" itemProp="hasOfferCatalog">
                 <h3>24/7 Support</h3>
                 <p itemProp="description">
                   Questions? Issues? Our dedicated support team is available
                   around the clock to assist you. We're here to ensure your
                   experience is seamless.
                 </p>
                 <button
                   className="btn-learn-more"
                   onClick={() => navigate("/faq#support-info")}
                 >
                   Learn More
                 </button>
               </div>
             </div>
           </div>
         </section>

         <section id="pricing" className="pricing" aria-label="pricing-section">
           <div className="container">
             <h2>Pricing</h2>
             <div className="pricing-card" itemScope itemType="https://schema.org/Offer">
               <h3>Simple & Transparent</h3>
               <p itemProp="description">
                 At Crazy Claims, we believe in straightforward pricing. No hidden
                 fees, no complicated calculations—just a flat fee of $250 for each
                 claim filed. We're here to help you get what you deserve without
                 any extra hassle.
               </p>
               <div className="price-amount" itemProp="price">$250</div>
               <meta itemProp="priceCurrency" content="USD" />
               <button className="btn-file-claim" onClick={handleFileClaimClick}>
                 File Claim
               </button>
             </div>
           </div>
         </section>

         <section id="about" className="about" aria-label="about-section">
           <div className="container">
             <h2>About Us</h2>
             <div className="about-content" itemScope itemType="https://schema.org/Organization">
               <div className="about-image">
                 <img 
                   src={aboutUs_IMG} 
                   alt="Professional Freight Claim Filing Services by Crazy Claims" 
                   className="about-img"
                   itemProp="image"
                 />
               </div>
               <div className="about-text">
                 <p itemProp="description">
                   At Crazy Claims, we strive to make the claims process easy and accessible for individuals and businesses struggling with unpaid invoices. With years of experience, our dedicated claims team works tirelessly to help you recover the money you deserve. We're here to support you every step of the way, taking a small fee with the aim of helping you reclaim what's rightfully yours. If you've been waiting months for payment, let us fight for your right to be paid.
                 </p>
                 <p className="disclaimer">
                   <small itemProp="disclaimer">
                     Disclaimer: Crazy Claims does not guarantee that you will receive a payout from your claim. While we ensure that your claim is properly filed and that all necessary steps are taken to pursue the payout, the final outcome depends on the specific circumstances of each claim and decisions made by the relevant parties. Our role is to facilitate the process, not to determine the outcome.
                   </small>
                 </p>
               </div>
             </div>
           </div>
         </section>

         <section id="contact" className="contact" aria-label="contact-section">
           <div className="container">
             <h2>Contact Us</h2>
             <p>
               If you have any questions or need further information, don't
               hesitate to reach out to us.
             </p>
             <div className="contact-content">
               <form className="contact-form" itemScope itemType="https://schema.org/ContactPoint">
                 <input type="text" name="name" placeholder="Your Name" required />
                 <input
                   type="email"
                   name="email"
                   placeholder="Your Email"
                   required
                 />
                 <textarea
                   name="message"
                   rows="5"
                   placeholder="Your Message"
                   required
                 ></textarea>
                 <button type="submit" className="btn-primary">
                   Send Message
                 </button>
               </form>
             </div>
           </div>
         </section>
       </main>
     </div>

     {/* Schema.org JSON-LD */}
     <script type="application/ld+json">
       {`
         {
           "@context": "https://schema.org",
           "@type": "ProfessionalService",
           "name": "Crazy Claims",
           "description": "Professional freight claim filing service helping truckers and carriers recover unpaid invoices.",
           "priceRange": "$250 per claim",
           "address": {
             "@type": "PostalAddress",
             "addressCountry": "US"
           },
           "offers": {
             "@type": "Offer",
             "price": "250",
             "priceCurrency": "USD",
             "description": "Professional freight claim filing service"
           },
           "serviceType": [
             "Freight Claim Filing",
             "Transportation Claims",
             "Cargo Claims",
             "Invoice Recovery"
           ],
           "availableService": {
             "@type": "Service",
             "name": "Freight Claim Filing Service",
             "description": "Professional freight claim filing service for $250 per claim"
           }
         }
       `}
     </script>
   </>
 );
}

export default Home;