import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js"; // Stripe
import { Elements } from "@stripe/react-stripe-js"; // Stripe elements
import Header from "./Header"; // Import the Header component
import logo from "../logos/crazyClaims_Rough_Logo_1.png";
import notificationsIcon from "../icons/crazyClaims_Notifications_Icon.png";
import accountIcon from "../icons/crazyClaims_Account_Icon.png";
import claimNoticeIcon from "../icons/crazyClaims_ClaimNotice_Icon.png";
import "../stylings/Dashboard.css";
import { useNavigate, useLocation } from "react-router-dom";
import { getCurrentUser, fetchAuthSession, signOut } from "aws-amplify/auth"; // Updated Auth methods for v6
import CheckoutForm from "./CheckoutForm"; // Import the CheckoutForm component
import Loading from "./Loading"; // Import the Loading component
import uploadedProfileImage from "../imgs/crazyClaimsProfile_IMG.png"; // Correct image import
import apiBaseUrls from "../config";

// Load Stripe with your publishable key
const stripePromise = loadStripe(
  "pk_live_51Pslss2K6RFIy0cGxe84Oxnc0DTw5UMOlmVNAoTjcHRr2rgeFQOmqN6JwEykLbrpCNjgCmSkjnhvmkAIMzzO0Hd400xCpyJJat"
);

const Dashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [isClaimRemarksPopupOpen, setClaimRemarksPopupOpen] = useState(false); // For viewing claim remarks
  const [quickViewSelectedClaimId, setQuickViewSelectedClaimId] =
    useState(null);
  const [isQuickViewRemarksPopupOpen, setQuickViewRemarksPopupOpen] =
    useState(false);
  const [isClaimPopupOpen, setClaimPopupOpen] = useState(false);
  const [isClaimsOverviewPopupOpen, setClaimsOverviewPopupOpen] =
    useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false); // Sidebar menu state
  const [isReceiptModalOpen, setReceiptModalOpen] = useState(false); // State for receipt modal
  const [claimData, setClaimData] = useState(null); // Store claim details for receipt
  const [clientSecret, setClientSecret] = useState(""); // Store client secret for Stripe Payment Intent
  const [paymentStatus, setPaymentStatus] = useState(null); // Payment status from the URL
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false); // For custom alert popup
  const [username, setUsername] = useState(null); // State to store username (changed from userId)
  const [jwtToken, setJwtToken] = useState(null); // State to store JWT token
  const [userProfileImage, setUserProfileImage] =
    useState(uploadedProfileImage); // Profile image state, using imported image
  const [claimsFiled, setClaimsFiled] = useState(0); // Placeholder for claims filed count
  const [claimsProcessed, setClaimsProcessed] = useState(0); // Placeholder for processed claims count
  const [outstandingClaims, setOutstandingClaims] = useState(0); // State for outstanding claims count
  const [loading, setLoading] = useState(true); // Loading state
  const [stripeLoading, setStripeLoading] = useState(true); // Stripe loading state
  const [claimsData, setClaimsData] = useState([]); // State for storing claims data
  const [claimsPerPage, setClaimsPerPage] = useState(3); // State for claims per page
  const [showMore, setShowMore] = useState(false); // State for toggling "Show More/Less"
  const [isSupportSubmitting, setIsSupportSubmitting] = useState(false); // State for submission status
  const [supportSuccessMessage, setSupportSuccessMessage] = useState(""); // State for success message
  const [supportErrorMessage, setSupportErrorMessage] = useState(""); // State for error message
  const [sidebarMetrics, setSidebarMetrics] = useState({
    claimsFiled: 0,
    claimsProcessed: 0,
    outstandingClaims: 0,
    user_info: { first_name: "", last_name: "", email: "" },
  });
  const navigate = useNavigate();
  const location = useLocation(); // To check state passed from navigation

  // Function to toggle "Show More" and "Show Less"
  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  // Function to navigate to Home sections
  const navigateToHomeSection = (sectionId) => {
    navigate("/"); // Navigate to the Home page
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 10,
          behavior: "smooth",
        });
      }
    }, 100); // Set timeout to ensure page fully renders before scrolling
  };

  // Check if we need to open the claim popup based on navigation state
  useEffect(() => {
    if (location.state && location.state.openClaimPopup) {
      setClaimPopupOpen(true);
    }
  }, [location.state]);

  // Fetch username and JWT token using AWS Amplify Auth v6
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser(); // Fetch current user
        const session = await fetchAuthSession(); // Fetch session

        // Extract username from user attributes
        const username = user.username; // Assuming 'username' is used in v6
        setUsername(username);
        setUserProfileImage(uploadedProfileImage); // Set the profile image

        // Get the JWT token
        const jwtToken = session.tokens.idToken; // Access JWT token from session
        setJwtToken(jwtToken);
        setLoading(false); // Stop loading once user data is fetched
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/Login");
      }
    };

    fetchUser();
  }, [navigate]);

  // Fetch sidebar metrics data including user info
  const fetchSidebarMetrics = async () => {
    try {
      const storedUserId = sessionStorage.getItem("user_id");
      const jwtToken = sessionStorage.getItem("jwtToken");

      console.log("Stored user ID:", storedUserId); // Logging for debugging
      console.log("Stored JWT token:", jwtToken); // Logging for debugging

      if (!storedUserId || !jwtToken) {
        throw new Error("Missing user ID or JWT token in session storage");
      }

      // Construct the URL with query parameters for the GET request
      const url = `${apiBaseUrls.user_dashboard}/user-sidebar-claim-metrics?user_id=${storedUserId}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`, // Send JWT token in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const metrics = await response.json();

      // Update the sidebarMetrics state to include both claims data and user info
      setSidebarMetrics({
        claimsFiled: metrics.claim_metrics.total_claims,
        claimsProcessed: metrics.claim_metrics.resolved_claims,
        outstandingClaims: metrics.claim_metrics.outstanding_claims,
        user_info: metrics.user_info, // Add the user_info to the sidebarMetrics state
      });
    } catch (error) {
      console.error("Error fetching sidebar metrics:", error);
    }
  };

  // Fetch outstanding claims number for user
  useEffect(() => {
    fetchOutstandingClaims();
  }, [jwtToken]);

  // Fetch sidebar metrics on mount
  useEffect(() => {
    fetchSidebarMetrics(); // Fetch sidebar metrics when component mounts
  }, [jwtToken]);

  // Fetch claims data function (moved outside useEffect)
  const fetchClaimsData = async () => {
    try {
      const storedUserId = sessionStorage.getItem("user_id");
      const jwtToken = sessionStorage.getItem("jwtToken");

      if (!storedUserId || !jwtToken) {
        throw new Error("Missing user ID or JWT token in session storage");
      }

      const response = await fetch(
        `${apiBaseUrls.user_dashboard}/user-dashboard-claim-info`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`, // Send JWT token in headers
          },
          body: JSON.stringify({ user_id: storedUserId }), // Pass user_id in the body
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const claims = await response.json();
      setClaimsData(claims); // Save the claims data to state
    } catch (error) {
      console.error("Error fetching claims:", error);
    }
  };

  // Fetch outstanding claims for the user
  const fetchOutstandingClaims = async () => {
    try {
      const storedUserId = sessionStorage.getItem("user_id");
      const jwtToken = sessionStorage.getItem("jwtToken");

      if (!storedUserId || !jwtToken) {
        throw new Error("Missing user ID or JWT token in session storage");
      }

      const requestBody = JSON.stringify({ user_id: storedUserId });

      const response = await fetch(
        `${apiBaseUrls.user_dashboard}/get-outstanding-claims-number`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`, // Send JWT token in headers
          },
          body: requestBody, // Ensure body is correctly passed
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setOutstandingClaims(data.outstanding_claims);
    } catch (error) {
      console.error("Error fetching outstanding claims:", error);
    }
  };

  const [isBondsmanModalOpen, setBondsmanModalOpen] = useState(false);
  const [bondsmanInfo, setBondsmanInfo] = useState(null);
  const [selectedClaim, setSelectedClaim] = useState(null);

  // Function to handle click on resolved claim status to display bondsman info
  const handleResolvedClaimClick = (claim) => {
    if (claim.status.toLowerCase() === "resolved" && claim.bondsman_info) {
      setBondsmanInfo(claim.bondsman_info);
      setSelectedClaim(claim); // Set the selected claim here
      setBondsmanModalOpen(true);
    } else {
      alert("No bondsman information available for this claim.");
    }
  };

  // Fetch claims data on mount
  useEffect(() => {
    fetchClaimsData(); // Fetch claims when component mounts
  }, [jwtToken]);

  // Fetch the PaymentIntent client secret from your backend
  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrls.stripe}/create-payment-intent`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              amount: 25000, // $250.00 in cents
              currency: "usd",
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
        setStripeLoading(false); // Stop stripe loading once payment intent is created
      } catch (error) {
        console.error("Error fetching PaymentIntent:", error);
      }
    };

    createPaymentIntent();
  }, []);

  // Check if payment succeeded by looking at the URL query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get("status");

    if (status === "success") {
      setIsSuccessAlertVisible(true);
    }

    setPaymentStatus(status);
  }, []); // No dependency on window.location

  // Auto-dismiss success alert after 5 seconds
  useEffect(() => {
    if (isSuccessAlertVisible) {
      const timer = setTimeout(() => {
        setIsSuccessAlertVisible(false);
      }, 5000); // Alert disappears after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [isSuccessAlertVisible]);

  // Handle claim preparation (not submission)
  const handlePrepareClaim = async (claimDetails) => {
    const storedUserId = sessionStorage.getItem("user_id");

    if (!storedUserId || !jwtToken) {
      console.error("User ID or JWT token is not available.");
      alert("Unable to retrieve user information. Please try again.");
      return;
    }

    try {
      // Validate and encode files as base64
      const encodedFiles = await Promise.all(
        claimDetails.files.map((file) => {
          return new Promise((resolve, reject) => {
            if (file instanceof File || file instanceof Blob) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                resolve({
                  name: file.name,
                  content: base64String,
                });
              };
              reader.onerror = (error) => reject(error);
              reader.readAsDataURL(file);
            } else {
              console.error("Invalid file type detected:", file);
              reject(
                new Error(
                  "Each item in the 'files' array should be a File or Blob."
                )
              );
            }
          });
        })
      );

      // Store the claim details in state, including encoded files
      const preparedClaimData = {
        og_claim_num: claimDetails.og_claim_num || null, // Include the original claim number
        claim_id: `CC-${Date.now()}`, // Generates a unique claim number
        filed_date: new Date().toISOString(),
        company_name: claimDetails.company_name || null, // Include the company name
        mc_number: claimDetails.mc_number || null, // Include the MC number
        first_name: claimDetails.first_name,
        last_name: claimDetails.last_name,
        address: claimDetails.address,
        email: claimDetails.email,
        phone_number: claimDetails.phone_number,
        drivers_license: claimDetails.drivers_license, // Ensure this is included
        details: claimDetails.details,
        user_id: storedUserId,
        terms_accepted: claimDetails.terms_accepted,
        files: encodedFiles, // Include encoded files here
      };

      setClaimData(preparedClaimData); // Set claim data in state
      setReceiptModalOpen(true); // Open the receipt modal for payment
    } catch (error) {
      console.error("Error encoding files:", error);
    }
  };

  // Handle claim submission after successful payment
  const handleSuccess = async () => {
    setReceiptModalOpen(false); // Close receipt modal after claim is filed

    // Prepare claim data for the email
    const emailData = {
      email: claimData.email,
      first_name: claimData.first_name,
      last_name: claimData.last_name,
      claim_id: claimData.claim_id,
      amount_paid: 250.0, // Default to $250.00
    };

    console.log("Email data being sent:", emailData);

    try {
      // Send the email using the email API
      const response = await fetch(
        `${apiBaseUrls.email}/email-payment-confirmation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`, // Include JWT token for authentication
          },
          body: JSON.stringify({ claim_data: emailData }), // Pass the email data in the body
        }
      );

      if (response.ok) {
        console.log("Confirmation email sent successfully");
      } else {
        console.error(
          "Failed to send confirmation email:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error sending confirmation email:", error);
    }

    // Open the confirmation modal after the receipt modal has closed
    setTimeout(() => {
      setConfirmationModalOpen(true);
    }, 300); // Delay slightly to ensure smooth transition

    refreshDashboardData(); // Refresh data to update outstanding claims and filed claims
    navigate("/Dashboard"); // Redirect to the dashboard on success
  };

  // Refresh the dashboard data including outstanding claims and filed claims
  const refreshDashboardData = () => {
    fetchOutstandingClaims(); // Fetch and update outstanding claims
    fetchClaimsData(); // Fetch and update the filed claims data
  };

  // Function to close the confirmation modal and show the success alert afterward
  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
    setTimeout(() => {
      setIsSuccessAlertVisible(true); // Show success alert after closing the confirmation modal
    }, 300); // Slight delay for smoother transition
  };

  // Handle customer support form submission
  const handleSubmitSupportForm = async (event) => {
    event.preventDefault();
    setIsSupportSubmitting(true);

    const formData = new FormData(event.target);
    const email = formData.get("email");
    const reason = formData.get("reason");
    const severity = formData.get("severity");
    const claimNumber = formData.get("claim-number");
    const message = formData.get("message");

    const storedUserId = sessionStorage.getItem("user_id"); // Retrieve user_id from session storage

    if (!storedUserId) {
      setSupportErrorMessage("User ID is missing. Please log in again.");
      setIsSupportSubmitting(false);
      return;
    }

    const payload = {
      user_id: storedUserId, // Include user_id
      email,
      reason,
      severity,
      claim_number: claimNumber,
      message,
    };

    try {
      const jwtToken = sessionStorage.getItem("jwtToken");

      const response = await fetch(
        `${apiBaseUrls.user_dashboard}/user-support-request-post`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSupportSuccessMessage("Your support request has been submitted.");
        event.target.reset(); // Clear the form fields
      } else {
        const errorData = await response.json();
        setSupportErrorMessage(
          errorData.message || "Failed to send the request."
        );
      }
    } catch (error) {
      setSupportErrorMessage(
        "An error occurred while sending the support request."
      );
    } finally {
      setIsSupportSubmitting(false);
    }
  };

  // Handle uploading more files for a specific claim and trigger UI refresh
  const handleUploadMoreFiles = async (event, claim_id) => {
    const files = Array.from(event.target.files); // Get the list of files
    const storedUserId = sessionStorage.getItem("user_id");

    if (!files.length) {
      alert("No files selected for upload.");
      return;
    }

    try {
      // Encode files to base64
      const encodedFiles = await Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result.split(",")[1]; // Remove data URL prefix
              resolve({
                name: file.name,
                content: base64String,
              });
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file); // Read file content as base64
          });
        })
      );

      // Create the request body
      const fileUploadDetails = {
        user_id: storedUserId,
        claim_id: claim_id,
        files: encodedFiles,
      };

      // Call the file-upload API
      const response = await fetch(`${apiBaseUrls.file}/file-upload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`, // Include JWT token in the headers
        },
        body: JSON.stringify(fileUploadDetails), // Send the file data as JSON
      });

      if (!response.ok) {
        throw new Error("File upload failed.");
      }

      const data = await response.json();
      alert("Files uploaded successfully!");

      // Trigger the UI refresh by re-fetching the claims data
      fetchClaimsData();
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("There was an error uploading the files. Please try again.");
    }
  };

  const handleRemoveFile = async (fileName, claimId) => {
    try {
      const storedUserId = sessionStorage.getItem("user_id");
      const jwtToken = sessionStorage.getItem("jwtToken");

      console.log("Attempting to remove file with the following details:");
      console.log("User ID:", storedUserId);
      console.log("File Name:", fileName);
      console.log("Claim ID:", claimId);

      if (!storedUserId || !fileName || !claimId) {
        throw new Error(
          "Missing required parameters: user_id, file_name, or claim_id."
        );
      }

      const response = await fetch(`${apiBaseUrls.file}/file-upload-remove`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          file_name: fileName,
          user_id: storedUserId,
          claim_id: claimId,
        }),
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Failed to remove file.");
      }

      const data = await response.json();
      console.log("Response Data:", data);
      alert(data.message);

      // Refresh the claims data to update the UI
      fetchClaimsData();
    } catch (error) {
      console.error("Error removing file:", error);
      alert("There was an error removing the file. Please try again.");
    }
  };

  const [currentRemarks, setCurrentRemarks] = useState([]);
  const [currentRemarkIndex, setCurrentRemarkIndex] = useState(0);

  // Function to handle opening or toggling a claim's remarks
  const handleViewClaimRemarks = async (claimId) => {
    if (claimId === selectedClaimId && isClaimRemarksPopupOpen) {
      // If the same claim is clicked, hide the remarks
      setClaimRemarksPopupOpen(false);
      setSelectedClaimId(null); // Reset selected claim ID
    } else {
      // Fetch the remarks for the new claim
      const remarks = await fetchClaimRemarks(claimId);

      // Ensure remarks are set and start from the first remark
      setCurrentRemarks(remarks);
      setCurrentRemarkIndex(0); // Start with the first remark

      // Set the new claim ID and open the remarks popup
      setSelectedClaimId(claimId);
      setClaimRemarksPopupOpen(true); // Open the remarks popup
    }
  };

  // Function for Quick View Remarks
  const handleQuickViewClaimRemarks = async (claimId) => {
    if (claimId === quickViewSelectedClaimId && isQuickViewRemarksPopupOpen) {
      // If the same claim is clicked, hide the remarks
      setQuickViewRemarksPopupOpen(false);
      setQuickViewSelectedClaimId(null); // Reset selected claim ID
    } else {
      // Fetch the remarks for the quick view claim
      const remarks = await fetchClaimRemarks(claimId);

      // Ensure remarks are set and start from the first remark
      setCurrentRemarks(remarks);
      setCurrentRemarkIndex(0); // Start with the first remark

      // Set the new claim ID and open the remarks popup for quick view
      setQuickViewSelectedClaimId(claimId);
      setQuickViewRemarksPopupOpen(true); // Open the quick view remarks popup
    }
  };

  // Function to fetch remarks for a specific claim
  const fetchClaimRemarks = async (claimId) => {
    try {
      const jwtToken = sessionStorage.getItem("jwtToken");

      if (!jwtToken) {
        throw new Error("Missing JWT token in session storage");
      }

      const response = await fetch(
        `${apiBaseUrls.claims}/claim-remarks-get?claim_id=${claimId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `HTTP error! Status: ${response.status}, Message: ${
            errorData.message || "Unknown error"
          }`
        );
      }

      const data = await response.json();

      // If remarks is not an array or is empty, handle it gracefully
      if (!Array.isArray(data.remarks) || data.remarks.length === 0) {
        console.log("No remarks found for this claim.");
        return [];
      }

      console.log("Fetched Remarks:", data.remarks); // Log the fetched remarks to check the response
      return data.remarks;
    } catch (error) {
      console.error("Error fetching claim remarks:", error);
      alert("Failed to load claim remarks. Please try again later.");
      return [];
    }
  };

  useEffect(() => {
    if (currentRemarks.length > 0) {
      setCurrentRemarkIndex(0); // Reset to the first remark when remarks are fetched
    }
  }, [currentRemarks]);

  // Open claim popup
  const openClaimPopup = () => {
    setClaimPopupOpen(true);
  };

  // Close claim popup
  const closeClaimPopup = () => {
    setClaimPopupOpen(false);
  };

  // Open claims overview popup
  const openClaimsPopup = () => {
    setClaimsOverviewPopupOpen(true);
  };

  // Close claims overview popup
  const closeClaimsPopup = () => {
    setClaimsOverviewPopupOpen(false);
  };

  // Toggle sidebar only if user is logged in
  const toggleSidebar = () => {
    if (isLoggedIn) {
      setSidebarOpen(!isSidebarOpen);
    } else {
      navigate("/login");
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut();
      sessionStorage.clear();
      alert("Logging out...");
      setIsLoggedIn(false);
      setSidebarOpen(false); // Close the sidebar
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Close receipt modal
  const closeReceiptModal = () => {
    setReceiptModalOpen(false);
  };

  // Close success alert
  const closeSuccessAlert = () => {
    setIsSuccessAlertVisible(false);
  };

  // Helper function to get status class based on claim status
  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case "resolved":
        return "status-resolved"; // CSS class for green
      case "in progress":
        return "status-in-progress"; // CSS class for yellow
      case "pending":
        return "status-pending"; // CSS class for red
      default:
        return "";
    }
  };

  // Render the loading component while data is being fetched
  if (loading || stripeLoading) {
    return <Loading />;
  }

  return (
    <div className={`dashboard ${isSidebarOpen ? "sidebar-open" : ""}`}>
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "active" : ""}`}>
        <div className="sidebar-header">
          <img src={userProfileImage} alt="Profile" className="profile-image" />
          {/*<h3>{username}</h3>*/}
          {/* Close button for sidebar */}
          <button className="close-sidebar-btn" onClick={toggleSidebar}>
            &times;
          </button>
        </div>

        {/* User Info Section */}
        <div className="user-info-box">
          <h4>
            {sidebarMetrics.user_info?.first_name}{" "}
            {sidebarMetrics.user_info?.last_name}
          </h4>
          <p>{sidebarMetrics.user_info?.email}</p>
        </div>

        <div className="sidebar-content">
          <p>Claims Filed: {sidebarMetrics.claimsFiled}</p>
          <p>Claims Processed: {sidebarMetrics.claimsProcessed}</p>
          <p>Outstanding Claims: {sidebarMetrics.outstandingClaims}</p>
        </div>

        <div className="sidebar-footer">
          <button className="btn-logout" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>

      {isSidebarOpen && (
        <div className="overlay active" onClick={toggleSidebar}></div>
      )}

      {/* Main Content */}
      <main className="dashboard-main">
        {/* Welcome Section */}
        <section className="welcome-section">
          <h2>Welcome to Your Dashboard</h2>
          <p>
            Manage your claims, check statuses, and reach out for support—all in
            one place.
          </p>
        </section>

        {/* Success Alert Popup */}
        {isSuccessAlertVisible && (
          <div className="custom-alert success-alert">
            <p>Claim filed successfully!</p>
            <button className="close-alert-btn" onClick={closeSuccessAlert}>
              &times;
            </button>
          </div>
        )}

        {/* Metrics Section */}
        <section className="metrics-section">
          <div className="metric-card">
            <h3>File a New Claim</h3>
            <p id="metric-text">
              Start a new claim process by clicking the button below.
            </p>
            <button className="btn-file-claim" onClick={openClaimPopup}>
              File a Claim
            </button>
          </div>
          <div className="metric-card">
            <h3>Outstanding Claims</h3>
            <p>
              <span id="outstanding-claims-count">
                {outstandingClaims !== null ? outstandingClaims : "Loading..."}
              </span>
            </p>
          </div>
          <div className="metric-card">
            <h3>Check Filed Claims</h3>
            <p id="metric-text">
              Track the status of your previously filed claims.
            </p>
            <button className="btn-check-claims" onClick={openClaimsPopup}>
              Check Claims
            </button>
          </div>
        </section>

        {isClaimsOverviewPopupOpen && (
          <>
            <div className="overlay active" onClick={closeClaimsPopup}></div>
            <div
              id="claims-overview-popup"
              className="claims-popup-window active"
            >
              <div className="claims-popup-content">
                <button className="close-popup" onClick={closeClaimsPopup}>
                  &times;
                </button>
                <h2 className="claims-header-title">Your Filed Claims</h2>
                <div className="claims-grid">
                  {claimsData && claimsData.length > 0 ? (
                    claimsData.map((claim) => (
                      <div className="claims-row" key={claim.claim_id}>
                        <div className="claim-column">
                          <p className="claim-popup-id">
                            <strong>Claim #{claim.claim_id}</strong>
                          </p>
                          <p
                            className={`claim-status ${getStatusClass(
                              claim.status
                            )}`}
                            onClick={() => handleResolvedClaimClick(claim)}
                          >
                            Status: {claim.status}
                          </p>
                          <p>
                            Date Filed:{" "}
                            {new Date(claim.filed_date).toLocaleDateString()}
                          </p>
                        </div>
                        <div className="claim-column">
                          <p>Assigned Filer: {claim.assigned_filer || "N/A"}</p>
                        </div>

                        {/* Uploaded Files Column */}
                        <div className="claim-column">
                          <p>
                            <strong>Uploaded Files:</strong>
                          </p>
                          <ul className="claim-file-list">
                            {claim.file_urls &&
                            Object.keys(claim.file_urls).length > 0 ? (
                              Object.keys(claim.file_urls).map(
                                (fileName, index) => (
                                  <li key={index} className="claim-file-item">
                                    <a
                                      href={claim.file_urls[fileName]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {fileName}
                                    </a>
                                    <span
                                      className="claim-remove-file-icon"
                                      onClick={() =>
                                        handleRemoveFile(
                                          fileName,
                                          claim.claim_id
                                        )
                                      }
                                      title="Remove file"
                                    >
                                      &#x2212;
                                    </span>
                                  </li>
                                )
                              )
                            ) : (
                              <li className="claim-no-files">
                                No files uploaded
                              </li>
                            )}
                          </ul>
                          <button
                            className="btn-upload-file"
                            onClick={() =>
                              document
                                .getElementById(`file-input-${claim.claim_id}`)
                                .click()
                            }
                          >
                            Upload More Files
                          </button>
                          <input
                            type="file"
                            id={`file-input-${claim.claim_id}`}
                            style={{ display: "none" }}
                            multiple
                            onChange={(e) =>
                              handleUploadMoreFiles(e, claim.claim_id)
                            }
                          />
                        </div>

                        {/* Notice Icon */}
                        <img
                          src={claimNoticeIcon}
                          alt="Claim Notice Icon"
                          className="claim-popup-notice-icon"
                          onClick={() => handleViewClaimRemarks(claim.claim_id)}
                        />

                        {/* Claim Remarks Section - Only show when selected */}
                        {selectedClaimId === claim.claim_id &&
                          isClaimRemarksPopupOpen && (
                            <div className="popup-claim-remarks-section">
                              {currentRemarks.length > 0 ? (
                                <>
                                  <p className="popup-remark-label">
                                    <strong>
                                      Remark {currentRemarkIndex + 1} of{" "}
                                      {currentRemarks.length}
                                    </strong>
                                  </p>
                                  <p className="popup-remark-text">
                                    {
                                      currentRemarks[currentRemarkIndex]
                                        .remark_text
                                    }
                                  </p>

                                  {/* Add navigation buttons if there are multiple remarks */}
                                  {currentRemarks.length > 1 && (
                                    <div className="remark-navigation">
                                      <button
                                        onClick={() =>
                                          setCurrentRemarkIndex((prev) =>
                                            Math.max(0, prev - 1)
                                          )
                                        }
                                        disabled={currentRemarkIndex === 0}
                                      >
                                        Previous
                                      </button>
                                      <button
                                        onClick={() =>
                                          setCurrentRemarkIndex((prev) =>
                                            Math.min(
                                              currentRemarks.length - 1,
                                              prev + 1
                                            )
                                          )
                                        }
                                        disabled={
                                          currentRemarkIndex ===
                                          currentRemarks.length - 1
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <p className="no-remark-text">
                                  No remarks for this claim at the moment.
                                </p>
                              )}
                            </div>
                          )}
                      </div>
                    ))
                  ) : (
                    <p>No claims found</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Filed Claims Quick View */}
        <section id="filed-claims" className="claims-section">
          <div className="claims-header">
            <h2>Filed Claims - Quick View</h2>
            {/* Show More/Less Button and Number Selector */}
            <div className="show-more-container">
              <label htmlFor="claims-group">Show:</label>
              <select
                id="claims-group"
                value={claimsPerPage}
                onChange={(e) => setClaimsPerPage(Number(e.target.value))}
                className="claims-selector"
              >
                <option value="3">3</option>
                <option value="6">6</option>
                <option value="9">9</option>
              </select>
            </div>
          </div>
          <div className="claims-list">
            {claimsData && claimsData.length > 0 ? (
              claimsData
                .slice(0, showMore ? claimsData.length : claimsPerPage)
                .map((claim) => (
                  <div className="claim-item" key={claim.claim_id}>
                    <div className="claim-info">
                      <div className="claim-details">
                        <h4>Claim #{claim.claim_id}</h4>
                        {claim.og_claim_num && (
                          <p>
                            <strong>Original Invoice Claim #:</strong>{" "}
                            {claim.og_claim_num}
                          </p>
                        )}
                        <p
                          className={`claim-status ${getStatusClass(
                            claim.status
                          )}`}
                          onClick={() => handleResolvedClaimClick(claim)}
                        >
                          Status: {claim.status}
                        </p>
                        <p>
                          Date Filed:{" "}
                          {new Date(claim.filed_date).toLocaleDateString()}
                        </p>
                        <p>Claim Details: {claim.details}</p>
                        <div className="claim-notice-wrapper">
                          <img
                            src={claimNoticeIcon}
                            alt="Claim Notice Icon"
                            className="claim-notice-icon"
                            onClick={() =>
                              handleQuickViewClaimRemarks(claim.claim_id)
                            }
                          />
                          {claim.claim_id === quickViewSelectedClaimId &&
                            isQuickViewRemarksPopupOpen && (
                              <div className="claim-remarks-section">
                                {currentRemarks.length > 0 ? (
                                  <>
                                    <p className="remark-label">
                                      <strong>
                                        Remark {currentRemarkIndex + 1} of{" "}
                                        {currentRemarks.length}
                                      </strong>
                                    </p>
                                    <p className="remark-text">
                                      {
                                        currentRemarks[currentRemarkIndex]
                                          .remark_text
                                      }
                                    </p>

                                    {/* Remark Navigation */}
                                    <div className="remark-navigation">
                                      <button
                                        className="prev-remark-btn"
                                        onClick={() =>
                                          setCurrentRemarkIndex((prevIndex) =>
                                            Math.max(prevIndex - 1, 0)
                                          )
                                        }
                                        disabled={currentRemarkIndex === 0}
                                      >
                                        Previous
                                      </button>
                                      <button
                                        className="next-remark-btn"
                                        onClick={() =>
                                          setCurrentRemarkIndex((prevIndex) =>
                                            Math.min(
                                              prevIndex + 1,
                                              currentRemarks.length - 1
                                            )
                                          )
                                        }
                                        disabled={
                                          currentRemarkIndex ===
                                          currentRemarks.length - 1
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <p className="no-remark-text">
                                    No remarks for this claim at the moment.
                                  </p>
                                )}
                              </div>
                            )}
                        </div>
                      </div>

                      {/* Uploaded Files Section */}
                      <div className="file-list">
                        <p>Uploaded Files:</p>
                        <ul>
                          {claim.file_urls &&
                          Object.keys(claim.file_urls).length > 0 ? (
                            Object.keys(claim.file_urls).map(
                              (fileName, index) => {
                                const fileUrl = claim.file_urls[fileName];
                                return (
                                  <li key={index} className="file-item">
                                    <a
                                      href={fileUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {fileName}
                                    </a>
                                    <span
                                      className="remove-file-icon"
                                      onClick={() =>
                                        handleRemoveFile(
                                          fileName,
                                          claim.claim_id
                                        )
                                      }
                                      title="Remove file"
                                    >
                                      &#x2212; {/* Minus sign */}
                                    </span>
                                  </li>
                                );
                              }
                            )
                          ) : (
                            <li>No files uploaded</li>
                          )}
                        </ul>
                        <button
                          className="btn-upload-file"
                          onClick={() =>
                            document
                              .getElementById(`file-input-${claim.claim_id}`)
                              .click()
                          }
                        >
                          Upload More Files
                        </button>
                        <input
                          type="file"
                          id={`file-input-${claim.claim_id}`}
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) =>
                            handleUploadMoreFiles(e, claim.claim_id)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="no-claims-message">
                <p>
                  <strong style={{ textAlign: "center", display: "block" }}>
                    No Claims Found
                  </strong>
                  <br />
                  Please check back later or file a new claim.
                </p>
                <div className="claims-spinner"></div>
              </div>
            )}
          </div>
        </section>

        {/* Customer Support Section */}
        <section id="support" className="support-section">
          <h2>Customer Support</h2>
          <div className="support-box">
            <h3>Need Assistance?</h3>
            <p>
              Reach out to our support team if you have any questions or issues.
            </p>
            <form className="support-form" onSubmit={handleSubmitSupportForm}>
              <label htmlFor="email">Your Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                className="email-input"
                placeholder="Enter your email"
                required
              />

              <label htmlFor="reason">Reason for Message:</label>
              <select id="reason" name="reason" className="dropdown">
                <option value="general">General Inquiry</option>
                <option value="technical">Technical Issue</option>
                <option value="billing">Billing Issue</option>
                <option value="other">Other</option>
              </select>

              <label htmlFor="severity">Severity:</label>
              <select id="severity" name="severity" className="dropdown">
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="urgent">Urgent</option>
              </select>

              <label htmlFor="claim-number">Claim Number:</label>
              <input
                type="text"
                id="claim-number"
                name="claim-number"
                className="claim-number-input"
                placeholder="Enter your claim number"
              />

              <textarea
                name="message"
                rows="4"
                placeholder="Your message..."
                required
              ></textarea>

              <button
                type="submit"
                className="btn-send-message"
                disabled={isSupportSubmitting}
              >
                {isSupportSubmitting ? "Sending..." : "Send Message"}
              </button>

              {supportSuccessMessage && (
                <p className="success-message message">
                  {supportSuccessMessage}
                </p>
              )}
              {supportErrorMessage && (
                <p className="error-message message">{supportErrorMessage}</p>
              )}
            </form>
          </div>
        </section>
      </main>

      {/* File Claim Popup */}
      {isClaimPopupOpen && (
        <>
          <div className="overlay active" onClick={closeClaimPopup}></div>

          {/* Popup Modal */}
          <div id="file-claim-popup" className="claim-popup-window active">
            <div className="claim-popup-content">
              <button className="close-popup" onClick={closeClaimPopup}>
                &times;
              </button>
              <h2>File a New Claim</h2>
              <p className="invoice-disclaimer">
                Please note: The claim you are filing is specifically for unpaid
                invoices.
                <br />
                <br />
                <strong>Important:</strong> The claim must be filed by the
                individual whose name appears on the invoice. Claims cannot be
                submitted on behalf of another person.
                <br />
                <br />
                If this claim is being filed for someone else, have them{" "}
                <a href="/contact-us" className="contact-link">
                  contact us
                </a>
                .
              </p>
              <form
                className="file-claim-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  const claimDetails = {
                    og_claim_num: e.target["og-claim-num"].value || null, // Original Invoice Claim #
                    company_name: e.target["company-name"].value || null, // New field for Company Name
                    mc_number: e.target["mc-number"].value || null, // New optional field for MC #
                    first_name: e.target["first-name"].value,
                    last_name: e.target["last-name"].value,
                    address: e.target["address"].value,
                    email: e.target["email"].value,
                    phone_number: e.target["phone"].value,
                    drivers_license: e.target["drivers-license"].value,
                    details: e.target["additional-details"].value,
                    terms_accepted: e.target["terms-accepted"].checked,
                    files: [
                      e.target["file1"].files[0],
                      e.target["file2"].files[0],
                      e.target["file3"].files[0],
                      e.target["file4"].files[0],
                    ].filter((file) => file !== undefined),
                  };

                  handlePrepareClaim(claimDetails);
                }}
              >
                {/* Original Invoice Claim Number (Optional) */}
                <label htmlFor="og-claim-num">Original Invoice # :</label>
                <input
                  type="text"
                  id="og-claim-num"
                  name="og-claim-num"
                  placeholder="Enter original claim number"
                />

                {/* Company Name */}
                <label htmlFor="company-name">Your Company Name:</label>
                <input
                  type="text"
                  id="company-name"
                  name="company-name"
                  placeholder="Enter company name"
                  required
                />

                {/* MC # (Optional) */}
                <label htmlFor="mc-number">MC # :</label>
                <input
                  type="text"
                  id="mc-number"
                  name="mc-number"
                  placeholder="Enter MC number (if applicable)"
                />

                {/* First Name */}
                <label htmlFor="first-name">First Name:</label>
                <input type="text" id="first-name" name="first-name" required />

                {/* Last Name */}
                <label htmlFor="last-name">Last Name:</label>
                <input type="text" id="last-name" name="last-name" required />

                {/* Address */}
                <label htmlFor="address">Address:</label>
                <input type="text" id="address" name="address" required />

                {/* Email */}
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />

                {/* Phone */}
                <label htmlFor="phone">Phone Number:</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  required
                  pattern="\d{3}-\d{3}-\d{4}"
                  placeholder="123-456-7890"
                />

                {/* Driver's License */}
                <label htmlFor="drivers-license">Driver's License:</label>
                <input
                  type="text"
                  id="drivers-license"
                  name="drivers-license"
                  required
                />

                {/* File Upload Instructions */}
                <strong>
                  <p className="upload-instructions">
                    Please upload the required three files to support your
                    claim:
                  </p>
                </strong>
                <ul className="upload-instructions-list">
                  <strong>
                    <li>1. Original Invoice (Required)</li>
                  </strong>
                  <strong>
                    <li>2. Bill of Lading (Required)</li>
                  </strong>
                  <strong>
                    <li>3. Rate Confirmation (Required)</li>
                  </strong>
                  <li>
                    4. Additional Document (Optional): Recommended uploads
                    include a copy of the notice of assignment from a factoring
                    company or other supporting documents.
                  </li>
                </ul>
                <br />

                {/* File Upload Inputs */}
                <strong>
                  <label htmlFor="file1">File 1: Original Invoice</label>
                </strong>
                <input type="file" id="file1" name="file1" required />

                <strong>
                  <label htmlFor="file2">File 2: Bill of Lading</label>
                </strong>
                <input type="file" id="file2" name="file2" required />

                <strong>
                  <label htmlFor="file3">File 3: Rate Confirmation</label>
                </strong>
                <input type="file" id="file3" name="file3" required />

                <label htmlFor="file4">
                  File 4: Optional (e.g., Notice of Assignment from Factoring
                  Company)
                </label>
                <input type="file" id="file4" name="file4" />

                {/* Additional Details Textbox */}
                <label htmlFor="additional-details">Additional Details:</label>
                <textarea
                  id="additional-details"
                  name="additional-details"
                  rows="4"
                  placeholder="Provide any additional information about the claim..."
                ></textarea>

                {/* Terms and Conditions */}
                <div className="terms-conditions">
                  <label htmlFor="terms-accepted" className="terms-label">
                    I agree to the{" "}
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="terms-link"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                  <input
                    type="checkbox"
                    id="terms-accepted"
                    name="terms-accepted"
                    required
                    className="terms-checkbox"
                  />
                </div>

                <button type="submit" className="btn-submit-claim">
                  Submit Claim
                </button>
              </form>
            </div>
          </div>
        </>
      )}

      {/* Receipt Modal */}
      {isReceiptModalOpen && (
        <>
          {/* Overlay for dimming the background */}
          <div className="overlay active" onClick={closeReceiptModal}></div>

          <div
            id="summary-payment-popup"
            className="claim-popup-window active receipt-modal"
          >
            <div className="claim-popup-content receipt-content">
              <button className="close-popup" onClick={closeReceiptModal}>
                &times;
              </button>
              <h2>Claim Receipt</h2>

              {/* Display Claim Information */}
              <div className="receipt-details">
                {/* Display Original Claim Number */}
                <p>
                  <strong>Original Invoice Claim #:</strong>{" "}
                  {claimData?.og_claim_num || "N/A"}
                </p>
                <p>
                  <strong>Claim Number:</strong> {claimData?.claim_id}
                </p>
                <p>
                  <strong>Filed On:</strong>{" "}
                  {new Date(claimData?.filed_date).toLocaleDateString()}
                </p>
                {/* Display Company Name */}
                <p>
                  <strong>Company Name:</strong>{" "}
                  {claimData?.company_name || "N/A"}
                </p>
                {/* Display MC # */}
                <p>
                  <strong>MC #:</strong> {claimData?.mc_number || "N/A"}
                </p>
                <p>
                  <strong>First Name:</strong> {claimData?.first_name}
                </p>
                <p>
                  <strong>Last Name:</strong> {claimData?.last_name}
                </p>
                <p>
                  <strong>Email:</strong> {claimData?.email}
                </p>
                <p>
                  <strong>Address:</strong> {claimData?.address}
                </p>
                <p>
                  <strong>Phone:</strong> {claimData?.phone_number}
                </p>
                <p>
                  <strong>Details:</strong>{" "}
                  {claimData?.details || "No additional details provided."}
                </p>
              </div>

              {/* Payment Summary */}
              <div className="payment-summary">
                <p>
                  <strong>Total Charge:</strong> $250.00
                </p>
              </div>

              {/* Stripe Payment Section */}
              <div className="stripe-section">
                {clientSecret && jwtToken ? (
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm
                      clientSecret={clientSecret}
                      claimData={claimData}
                      onSuccess={handleSuccess}
                      jwtToken={jwtToken}
                      closeClaimPopup={closeClaimPopup}
                      refreshDashboard={refreshDashboardData} // Pass refresh function to update dashboard data
                    />
                  </Elements>
                ) : (
                  <p>Loading payment details...</p>
                )}
              </div>

              {/* Terms and Conditions */}
              <div className="terms-conditions">
                <p className="terms-label">
                  By proceeding, you agree to our{" "}
                  <a
                    href="/terms-and-conditions"
                    target="_blank"
                    className="terms-link"
                  >
                    Terms and Conditions
                  </a>
                  .
                </p>
              </div>

              {/* Disclaimer */}
              <div className="payment-disclaimer">
                <small>
                  Disclaimer: Crazy Claims does not guarantee that you will
                  receive a payout from your claim. While we ensure that your
                  claim is properly filed and that all necessary steps are taken
                  to pursue the payout, the final outcome depends on the
                  specific circumstances of each claim and decisions made by the
                  relevant parties. Our role is to facilitate the process, not
                  to determine the outcome.
                </small>
              </div>
            </div>
          </div>
        </>
      )}

      {isConfirmationModalOpen && (
        <>
          {/* Overlay for dimming the background */}
          <div
            className="overlay active"
            onClick={closeConfirmationModal}
          ></div>

          <div className="claim-popup-window active confirmation-modal">
            <div className="claim-popup-content confirmation-content">
              <button className="close-popup" onClick={closeConfirmationModal}>
                &times;
              </button>
              <h2>Payment Confirmation</h2>

              {/* Display Claim Information */}
              <div className="confirmation-details">
                <p>
                  <strong>Claim Number:</strong> {claimData?.claim_id}
                </p>
                <p>
                  <strong>Filed On:</strong>{" "}
                  {new Date(claimData?.filed_date).toLocaleDateString()}
                </p>
                <p>
                  <strong>First Name:</strong> {claimData?.first_name}
                </p>
                <p>
                  <strong>Last Name:</strong> {claimData?.last_name}
                </p>
                <p>
                  <strong>Email:</strong> {claimData?.email}
                </p>
                <p>
                  <strong>Details:</strong>{" "}
                  {claimData?.details || "No additional details provided."}
                </p>
                <p>
                  <strong>Amount Paid:</strong> $250.00
                </p>
              </div>

              {/* Enhanced Confirmation Message */}
              <div className="confirmation-message">
                <p className="confirmation-email">
                  A confirmation email has been sent to{" "}
                  <strong>{claimData?.email}</strong>.
                </p>
                <p className="confirmation-instructions">
                  You can track the status of your claim from the{" "}
                  <strong>Dashboard</strong>.
                </p>
                <p className="confirmation-highlight">
                  Thank you for your payment of <strong>$250.00</strong>!
                </p>
              </div>

              {/* Disclaimer and Terms */}
              <div className="confirmation-disclaimer">
                <small>
                  Disclaimer: Crazy Claims does not guarantee that you will
                  receive a payout from your claim. While we ensure that your
                  claim is properly filed and that all necessary steps are taken
                  to pursue the payout, the final outcome depends on the
                  specific circumstances of each claim and decisions made by the
                  relevant parties. Our role is to facilitate the process, not
                  to determine the outcome.
                </small>
                <br />
                <br />
                <p>
                  For more information, please review our{" "}
                  <a href="/terms-and-conditions" className="terms-link">
                    Terms and Conditions
                  </a>
                  .
                </p>
              </div>

              <button
                className="btn-close-confirmation"
                onClick={closeConfirmationModal}
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}
      {isBondsmanModalOpen && (
        <div className="bondsman-modal">
          <div className="bondsman-modal-content">
            <button onClick={() => setBondsmanModalOpen(false)}>&times;</button>
            <h2>Bondsman Information</h2>
            <p style={{ fontStyle: "italic", color: "#7f8c8d" }}>
              Reliable support for your claims.
            </p>
            {bondsmanInfo ? (
              <div>
                {selectedClaim && (
                  <>
                    <h4>Claim #{selectedClaim.claim_id}</h4>
                    {selectedClaim.og_claim_num ? (
                      <p>
                        <strong>Original Invoice Claim #:</strong>{" "}
                        {selectedClaim.og_claim_num}
                      </p>
                    ) : (
                      <p>
                        <strong>Original Invoice Claim #:</strong> Not Available
                      </p>
                    )}
                  </>
                )}

                <p>
                  <strong>Company:</strong> {bondsmanInfo.company_name}
                </p>
                <p>
                  <strong>Phone:</strong> {bondsmanInfo.phone}
                </p>
                <p>
                  <strong>Email:</strong> {bondsmanInfo.email}
                </p>
                <p>
                  <strong>Contact:</strong> {bondsmanInfo.contact_name}
                </p>
                <p>
                  <strong>Address:</strong> {bondsmanInfo.address}
                </p>
              </div>
            ) : (
              <p>No bondsman information available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
