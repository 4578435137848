import React from "react";
import "../stylings/Footer.css";

const Footer = () => {
  return (
    <footer className="footer" itemScope itemType="https://schema.org/WPFooter">
      <div className="container">
        <div className="footer-content">
          <p itemScope itemType="https://schema.org/ContactPoint">
            <strong>Contact Us:</strong>{" "}
            <a href="mailto:support@crazyclaims.com" itemProp="email">support@crazyclaims.com</a> <br />
            Phone: <a href="tel:1-888-382-7837" itemProp="telephone">1-888-382-7837</a> | 
            Alt: <a href="tel:1-888-400-2163" itemProp="telephone">1-888-400-2163</a>
          </p>
          <p itemScope itemType="https://schema.org/Organization">
            <strong>Follow Us:</strong>
            <a href="https://twitter.com/crazyclaims" className="footer-social-link" rel="noopener noreferrer" itemProp="sameAs">Twitter</a> |
            <a href="https://facebook.com/crazyclaims" className="footer-social-link" rel="noopener noreferrer" itemProp="sameAs">Facebook</a> |
            <a href="https://linkedin.com/company/crazyclaims" className="footer-social-link" rel="noopener noreferrer" itemProp="sameAs">LinkedIn</a>
          </p>
          <p>
            <strong>Need more help?</strong> Visit our <a href="/support">Support Page</a> or{" "}
            <a href="mailto:support@crazyclaims.com">Contact Us</a>.
          </p>
          
          <hr />
          <p className="disclaimer">
            <small itemProp="description">
              Disclaimer: Crazy Claims does not guarantee that you will receive a payout from your claim. While we ensure that your claim is properly filed and that all necessary steps are taken to pursue the payout, the final outcome depends on the specific circumstances of each claim and decisions made by the relevant parties. Our role is to facilitate the process.
            </small>
          </p>
          <p itemScope itemType="https://schema.org/Organization">
            <span itemProp="name">&copy; 2024 Crazy Claims</span>. All Rights Reserved.
          </p>
          <p>
            <small>
              Website developed by <a href="https://theradicalsoftware.com" target="_blank" rel="noopener noreferrer">Radical Software</a>
            </small>
          </p>
        </div>
      </div>

      {/* Hidden Schema.org JSON-LD */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Crazy Claims",
            "description": "Professional freight claim filing service helping truckers and carriers recover unpaid invoices",
            "url": "https://crazyclaims.com",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": ["1-888-382-7837", "1-888-400-2163"],
              "contactType": "customer service",
              "email": "support@crazyclaims.com",
              "areaServed": "US",
              "availableLanguage": "English"
            },
            "sameAs": [
              "https://twitter.com/crazyclaims",
              "https://facebook.com/crazyclaims",
              "https://linkedin.com/company/crazyclaims"
            ]
          }
        `}
      </script>
    </footer>
  );
};

export default Footer;