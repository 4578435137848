const apiBaseUrls = {
  claims: process.env.REACT_APP_CLAIMS_API_BASE_URL,
  email: process.env.REACT_APP_EMAIL_API_BASE_URL,
  file: process.env.REACT_APP_FILE_API_BASE_URL,
  filer_dashboard: process.env.REACT_APP_FILER_DASHBOARD_API_BASE_URL,
  stripe: process.env.REACT_APP_STRIPE_API_BASE_URL,
  user_dashboard: process.env.REACT_APP_USER_DASHBOARD_API_BASE_URL,
  users: process.env.REACT_APP_USERS_API_BASE_URL,
};

export default apiBaseUrls;
